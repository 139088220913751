import "dayjs/locale/es";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import moment, { isSame } from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { connect } from "react-redux";
import isNil from "lodash/isNil";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeaderComponent from "../../components/header";
import BackDark from "../../assets/img/swip-back-dark.png";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { setDataUserProfile } from "../../utils/dispatchs/userProfileDispatch";
import AlertDialog from "../../components/dialogConfirm";
import Loader from "../../components/loader";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr 70px;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  .container-qr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    font-size: 0.8em;
  }
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  z-index: 1;
  .buttons-actions {
    margin-top: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  font-size: 1em;
`;

const CardDetail = styled.div`
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  margin-top: 4em;

  .item-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .item-info {
      display: flex;
      flex-direction: column;
    }
    .item-cost {
      display: flex;
      flex-direction: column;
    }
  }
`;

const ScheduleAppointment = (props) => {
  const initialState = {
    description: [],
    gpsMonthly: 0,
    numberOfUnits: 0,
    totalGps: 0,
  };
  const { callGlobalActionApi, dataProfile, setDataUserProfile } = props;
  const [isNavigator, setIsNavigator] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataPersonalInfo, setDataPersonalInfo] = useState({});
  const [scheduleDate, setScheduleDate] = useState("");
  const [scheduleHour, setScheduleHour] = useState("");
  const [scheduleFormat, setScheduleFormat] = useState({
    formatUser: "",
    formatSystem: "",
  });
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [searchParams] = useSearchParams();
  const dataUser = searchParams.get("d");
  const navigate = useNavigate();

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  const disableWeekends = (date) => {
    const dayOfWeek = date.day();

    return dayOfWeek === 0 || dayOfWeek === 6;
  };

  const disableToday = (date) => {
    return date.isSame(moment(), "day");
  };

  const handleGetPersonalInfo = async (id: string) => {
    try {
      const response = await callGlobalActionApi(
        {},
        id,
        API_CONSTANTS.PROCESS.GET_PERSONAL_INFO,
        "GET",
        false
      );

      setDataPersonalInfo(response);
    } catch (error) {
      window.alert("Error al obtener los procesos");
    }
  };

  const handleGetInfo = async (encode) => {
    try {
      const dataDecode = window.atob(encode);
      const dataParse = JSON.parse(dataDecode);
      const idSystemUser = dataParse.idSystemUser;

      const responseProfile = await callGlobalActionApi(
        {},
        idSystemUser,
        API_CONSTANTS.USER.USER_GET_LOGIN,
        "GET",
        false
      );

      await handleGetPersonalInfo(idSystemUser);
      await setDataUserProfile(responseProfile);
    } catch (error) {}
  };

  const handleUpdateProcessesById = async (data, id) => {
    try {
      await callGlobalActionApi(
        data,
        id,
        API_CONSTANTS.PROCESS.UPDATE_BY_ID_PROCESSES,
        "PUT",
        false
      );
      setIsOpenDialog(false);
      navigate("/confirmado?type=schedule");
    } catch (error) {
      window.alert("Error al obtener los procesos");
    }
  };

  useEffect(() => {
    let navegador = navigator.userAgent;
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsNavigator(false);
      handleGetInfo(dataUser);
    } else {
      setIsNavigator(true);
    }
  }, []);

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <AlertDialog
        open={isOpenDialog}
        handleAccept={async () => {
          try {
            setIsLoading(true);
            await handleUpdateProcessesById(
              {
                idTemplateEmail: 8,
                idSystemUser: dataProfile.idSystemUser,
                appointmentDate: scheduleFormat.formatSystem,
              },
              dataProfile.idProcesses
            );
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
          }
        }}
        handleClose={() => {
          setIsOpenDialog(false);
        }}
        content={
          <div>
            <p>{scheduleFormat.formatUser}</p>
          </div>
        }
      />
      <HeaderComponent type="light" />
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="backround-img"
          style={{
            position: "absolute",
          }}
        >
          <img src={BackDark} alt="" />
        </div>
        {isNavigator === true ? (
          <Main>
            <div className="container-qr">
              <div>
                Para continuar, por favor escanea el siguiente QR desde un
                dispositivo móvil
              </div>
              <div>
                <QRCode value={window.location.href} />
              </div>
            </div>
          </Main>
        ) : (
          <Main>
            <div className="main-info">
              <h3>Progamar cita</h3>
              <p>Elige una fecha y hora para la cita</p>
            </div>
            <p>Elige una fecha</p>
            <div>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
              >
                <DateCalendar
                  disablePast
                  disableHighlightToday={false}
                  onChange={(date) => {
                    const dateFormated = date.format("YYYY-MM-DD");
                    setScheduleDate(dateFormated);
                  }}
                  shouldDisableDate={(date) =>
                    disableWeekends(date) || disableToday(date)
                  }
                />
              </LocalizationProvider>
            </div>
            <p>Elige una hora</p>
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Hora de cita
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={scheduleHour}
                  label="Hora de cita"
                  onChange={(e) => {
                    setScheduleHour(e.target.value);
                  }}
                >
                  <MenuItem value="09:00:00-0600">09:00 am</MenuItem>
                  <MenuItem value="10:00:00-0600">10:00 am</MenuItem>
                  <MenuItem value="11:00:00-0600">11:00 am</MenuItem>
                  <MenuItem value="12:00:00-0600">12:00 pm</MenuItem>
                  <MenuItem value="13:00:00-0600">01:00 pm</MenuItem>
                  <MenuItem value="14:00:00-0600">02:00 pm</MenuItem>
                  <MenuItem value="15:00:00-0600">03:00 pm</MenuItem>
                  <MenuItem value="16:00:00-0600">04:00 pm</MenuItem>
                  <MenuItem value="17:00:00-0600">05:00 pm</MenuItem>
                  <MenuItem value="18:00:00-0600">06:00 pm</MenuItem>
                </Select>
              </FormControl>
            </div>

            <ButtonsGroup>
              <div className="buttons-actions">
                <ButtonNext
                  onClick={async () => {
                    const dateSchedule = `${scheduleDate}T${scheduleHour}`;
                    const formatDate =
                      moment(dateSchedule).format("DD MMMM YYYY");
                    const formatHour = moment(dateSchedule).format("h:mm a");
                    setScheduleFormat({
                      formatUser: `${formatDate} a las ${formatHour}`,
                      formatSystem: dateSchedule,
                    });
                    setIsOpenDialog(true);
                  }}
                >
                  Listo
                </ButtonNext>
              </div>
            </ButtonsGroup>
          </Main>
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataProfile } = state;
  return {
    dataProfile: dataProfile.dataProfile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserProfile: (data) => dispatch(setDataUserProfile(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleAppointment);

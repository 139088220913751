import { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import HeaderComponent from "../../components/header";
import BackDark from "../../assets/img/swip-back-dark.png";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import Loader from "../../components/loader";
import ENVIROMENT from "../../utils/constants/enviroments";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr 70px;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  z-index: 1;
  .buttons-actions {
    margin-top: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  font-size: 1em;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
  .button-add {
    background: var(--secondary-color);
    width: 5em;
    height: 5em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 50px;
      color: var(--light-color);
      height: 50px;
    }
  }
`;

const AddNewCar = (props) => {
  const { callGlobalActionApi, dataRegister } = props;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleSendInfoCreteProcess = async () => {
    try {
      setIsLoading(true);
      await callGlobalActionApi(
        {
          idProcesses: dataRegister.idProcesses,
        },
        null,
        API_CONSTANTS.PROCESS.INFO_CREATE,
        "POST",
        false
      );
      setIsLoading(false);
      await fetch(
        `${ENVIROMENT}${API_CONSTANTS.USER.USER_PROGRESS}${dataRegister.idSystemUser}?screen=5`
      );
      navigate("/informacion-bancaria");
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="light" />
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="backround-img"
          style={{
            position: "absolute",
          }}
        >
          <img src={BackDark} alt="" />
        </div>
        <Main>
          <div className="main-info">
            <h3>¿Deseas agregar más autos?</h3>
            <p>
              Te recordamos que con Swip tu crédito puede aumentar si agregas
              mas autos, has clic en + para añadir mas autos a tu cartera
            </p>
          </div>
          <ContainerButton>
            <div
              className="button-add"
              onClick={() => {
                navigate("/documentos-auto");
              }}
            >
              <AddIcon />
            </div>
          </ContainerButton>
          <ButtonsGroup>
            <div className="buttons-actions">
              <ButtonNext
                onClick={() => {
                  handleSendInfoCreteProcess();
                }}
              >
                No agregaré mas autos
              </ButtonNext>
            </div>
          </ButtonsGroup>
        </Main>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataRegister } = state;
  return {
    dataRegister: dataRegister.dataRegister,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCar);

import styled from "styled-components";

const Footer = styled.footer`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  background: ${(props) => props.backGround};
`;

const FooterComponent = ({ children = <div />, type = "light" }) => {
  return (
    <Footer
      backGround={
        type === "light" ? "var(--light-color)" : "var(--primary-color)"
      }
    >
      {children}
    </Footer>
  );
};

export default FooterComponent;

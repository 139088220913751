import isNil from "lodash/isNil";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { NumericFormat } from "react-number-format";

const FormDialogComponent = (props) => {
  const { open = false, limit, onClose, onCancel } = props;

  const [value, setValue] = useState("");

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onCancel();
        setValue(0);
      }}
    >
      <DialogTitle>Monto dinamico</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Monto limite {parseFormatCurrency(limit, 0, 2)} MXN
        </DialogContentText>
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
          <InputLabel htmlFor="standard-adornment-amount">Monto</InputLabel>
          <NumericFormat
            prefix="$"
            thousandSeparator=","
            displayType="input"
            // onClick={() => setInputType(!inputType)}
            value={value}
            decimalScale={3}
            onValueChange={(e) => {
              const floaValue = e?.floatValue || 0;

              if (floaValue <= limit) {
                setValue(floaValue);
              } else {
                window.alert("El monto no puede ser mayor al disponible");
              }
            }}
            customInput={Input}
          />
          {/* <Input
            value={value}
            type="number"
            id="standard-adornment-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          /> */}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
            setValue(0);
          }}
        >
          Cerrar
        </Button>
        <Button
          onClick={() => {
            if (value !== 0 && value <= limit) {
              onClose(value);
              setValue(0);
            } else {
              window.alert(
                "Ingresa otro monto dentro del monto disponible y que no sea 0"
              );
            }
          }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialogComponent;

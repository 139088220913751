import { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const LogOut = (props) => {
  const { purgeStore } = props;
  const handlerFinishSession = async () => {
    await purgeStore();
    await sessionStorage.clear();
    await localStorage.clear();
  };
  useEffect(() => {
    handlerFinishSession();
  }, []);

  return <Navigate to="/" />;
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  purgeStore: () => dispatch({ type: "PURGE" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogOut);

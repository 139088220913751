import styled from "styled-components";
import isUndefined from "lodash/isUndefined";
import { useParams } from "react-router-dom";
import ViewSelfie from "./subView/viewSelfie";
import ViewDocumentGeneric from "./subView/viewDocumentGeneric";
import GLOBAL_CONSTANTS from "../../utils/constants/globalConstants";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const UploadDocuments = (props) => {
  const { path, to, flowScreenDocument } = props;
  const { idScreen } = useParams();

  return (
    <>
      {flowScreenDocument.map((step, index) => {
      
        const pathDocuments = path;
        const isLast = isUndefined(flowScreenDocument[index + 1]) === true;
        const nextScreen = isLast
          ? to
          : `${pathDocuments}${flowScreenDocument[index + 1].idScreen}`;
        let component = <></>;
        if (
          idScreen === step.idScreen &&
          idScreen !== GLOBAL_CONSTANTS.SCREEN_SELFIE
        ) {
          component = (
            <ViewDocumentGeneric
              key={index}
              {...step}
              nextScreen={nextScreen}
              isLast={isLast}
            />
          );
        }

        if (idScreen === GLOBAL_CONSTANTS.SCREEN_SELFIE && idScreen === step.idScreen) {
          component = (
            <ViewSelfie
              key={index}
              {...step}
              nextScreen={nextScreen}
              isLast={isLast}
            />
          );
        }

        return component;
      })}
    </>
  );
};

export default UploadDocuments;

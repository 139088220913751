import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import BackDark from "../../assets/img/swip-back-dark.png";
import BackDarkBtn from "../../assets/img/swip-back-dark-btm.png";
import HeaderComponent from "../../components/header";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import { useState } from "react";
import { useOnChangeInput } from "../../hooks";
import { setDataUserRegister } from "../../utils/dispatchs/userRegisterDispatch";
import Loader from "../../components/loader";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  overflow-y: scroll;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  margin-top: 2em;
  .terms-conditions {
    font-size: 0.7em;
    color: var(-primary-color);
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .buttons-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  width: 70%;
  font-size: 1em;
`;

const ChangePassword = (props) => {
  const { callGlobalActionApi } = props;
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const navigate = useNavigate();
  const initialState = {
    password: "",
  };
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dataForm, handlerOnChange] = useOnChangeInput(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const handlerChangePassword = async (password) => {
    try {
      setIsLoading(true);
      if (dataForm.password === password) {
        await callGlobalActionApi(
          { ...dataForm, token },
          null,
          API_CONSTANTS.USER.CHANGE_PASSWORD,
          "POST",
          false
        );
        window.alert("Contraseña cambiada correctamente");
        setIsLoading(false);
        navigate("/login");
      } else {
        setIsLoading(false);
        throw new Error("Las contraseñas no coinciden");
      }
    } catch (error) {
      setIsLoading(false);
      window.alert(error);
    }
  };

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="light" isVisibleMenu={false} />
      <Main>
        <div className="backround-img-top">
          <img src={BackDark} alt="" />
        </div>
        <div className="backround-img-btm">
          <img src={BackDarkBtn} alt="" />
        </div>
        <div className="main-info">
          <h3>Cambio de contraseña</h3>
          <p>
            Te recordamos que sea una contraseña segura con letras mayúsculas y
            minúsculas, números y caracteres especiales.
          </p>
        </div>
        <div>
          <Box component="form" noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5em",
                marginTop: "2em",
              }}
            >
              <TextField
                required
                id="password-input"
                name="password"
                label="Ingresa tu contraseña nueva"
                type="password"
                defaultValue=""
                onChange={handlerOnChange}
              />
              <TextField
                required
                id="password-input-confirm"
                name="password"
                label="Confirma tu contraseña"
                type="password"
                defaultValue=""
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </div>
          </Box>
          <ButtonsGroup>
            <div className="buttons-actions">
              <ButtonNext
                onClick={() => {
                  handlerChangePassword(confirmPassword);
                }}
              >
                Cambiar
              </ButtonNext>
            </div>
          </ButtonsGroup>
        </div>
      </Main>
    </Container>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserRegister: (data) => dispatch(setDataUserRegister(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

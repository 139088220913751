import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";
import HeaderComponent from "../../components/header";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  .container-qr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    font-size: 0.8em;
  }
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const OnlyMobileVersion = (props) => {
  const { children } = props;
  const [isNavigator, setIsNavigator] = useState(false);
  let component = <></>;

  useEffect(() => {
    let navegador = navigator.userAgent;
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsNavigator(false);
    } else {
      setIsNavigator(true);
    }
  }, []);

  if (isNavigator) {
    component = (
      <Container>
        <HeaderComponent type="light" isVisibleMenu={false} />
        <Main>
          <div className="container-qr">
            <div>
              Para continuar, por favor escanea el siguiente QR desde un
              dispositivo móvil
            </div>
            <div>
              <QRCode value={window.location.href} />
            </div>
          </div>
        </Main>
      </Container>
    );
  } else {
    component = children;
  }

  return component;
};

export default OnlyMobileVersion;

import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import moment from "moment";
import Lottie from "react-lottie";
import animationData from "../../../assets/lottie/hour_glass.json";
import animationDataCar from "../../../assets/lottie/car_run.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptionsCar = {
  loop: true,
  autoplay: true,
  animationData: animationDataCar,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 1em;
  row-gap: 2em;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;

  .list-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list-info {
    font-family: "Kometa";
    display: flex;
    flex-direction: column;
    padding: 0px 1em;
    color: var(--primary-color);
    .list-concept-amount {
      font-size: 1.1em;
      display: flex;
      justify-content: space-between;
      font-weight: 300;
      font-style: normal;
      line-height: 15px;
    }
    .deposit-info {
      display: flex;
      justify-content: space-between;
      font-weight: 100;
      font-style: normal;
      font-size: 0.8em;
    }
  }
`;

const CircleIcon = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ComponentListHistory = (props) => {
  const { dataHistory, isApproved, dataProcess } = props;

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  const formattDate = (date: any) => {
    let resultDate = "";
    if (isEmpty(date) === false) {
      resultDate = moment(date).format("DD MMM YYYY");
    }
    return resultDate;
  };

  const handleGetComponent = (status) => {
    let component = <></>;

    if (status === 1) {
      component = (
        <div>
          <p>
            Tu solicitud de crédito está siendo procesada, en breve te
            notificarémos cuando esté PRE-APROBADO
          </p>
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      );
    } else if (status === 2) {
      component = (
        <div>
          <p>¡Estás a un paso de recibir tu crédito!</p>
          <Lottie options={defaultOptionsCar} height={250} width={250} />
        </div>
      );
    } else if (status === 3) {
      component = "¡Felicidades empieza a utilizar tu dinero!";
    }
    return component;
  };

  return (
    <ContainerList>
      {isEmpty(dataHistory) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              textAlign: "center",
            }}
          >
            {isApproved
              ? "¡Felicidades empieza a utilizar tu dinero!"
              : handleGetComponent(dataProcess.idStatus)}
          </h3>
        </div>
      )}
      {isEmpty(dataHistory) === false &&
        dataHistory.map((row, ix) => {
          return (
            <List key={row.idLoan}>
              <div className="list-icon">
                <CircleIcon>
                  <svg
                    width={12}
                    height={11}
                    viewBox="0 0 12 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.00001 2.18093e-05L12 11H6.05178e-06L6.00001 2.18093e-05Z"
                      fill="white"
                    />
                  </svg>
                </CircleIcon>
              </div>
              <div className="list-info">
                <div className="list-concept-amount">
                  <span>{row.concept}</span>
                  <span>{parseFormatCurrency(row.amountLoan, 0, 2)}</span>
                </div>
                <div className="deposit-info">
                  {/* <span>AMX ***186</span> */}
                  <span>-</span>
                  <span>{formattDate(row.requestedAt)}</span>
                </div>
              </div>
            </List>
          );
        })}
    </ContainerList>
  );
};

export default ComponentListHistory;

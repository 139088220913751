import { produce } from "immer";
import GLOBAL_CONSTANTS from "../constants/globalConstants";

const initialDataProcess = { default: "" };

const dataProcess = (state = initialDataProcess, action) => {
  return produce(state, (draft) => {
    const draftState = draft;
    switch (action.type) {
      case GLOBAL_CONSTANTS.SET_DATA_USER_PROCESS:
        draftState.dataProcess = action.dataProcess;
        break;
      default:
        return state;
    }
  });
};

export { dataProcess };

import { useEffect, useState } from "react";
import { Route, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import SlideRoutes from "react-slide-routes";
import styled from "styled-components";
import UserConfig from "../../assets/img/swip-setting.png";
import IconHome from "../../assets/img/swip-home.png";
import IconGuartante from "../../assets/img/swip-guarante.png";
import BackDark from "../../assets/img/swip-back-dark.png";
import EmptyAvatar from "../../assets/img/swip-empty-avatar.png";
import Credits from "./subView/credits";
import Payments from "./subView/payments";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import { setDataUserProcess } from "../../utils/dispatchs/userProcessDispatch";

const Container = styled.div`
  font-size: 16px;
  height: 100vh;
  display: grid;
  grid-template-rows: var(--size-header) 1fr;

  @media screen and (max-width: 520px) {
    font-size: 12px;
  }
  @media screen and (max-width: 380px) {
    font-size: 10px;
  }
`;

const Main = styled.main`
  background: var(--primary-color);
  height: calc(100vh - var(--size-header) - var(--size-footer));
  display: grid;
  grid-template-rows: 1fr 2fr;

  .container-history {
    position: relative;
  }
`;

const DashboardInfo = styled.div`
  position: relative;
  .backround-img {
    position: relative;
    img {
      width: 100%;
      position: absolute;
    }
  }
`;

const ContainInfo = styled.div`
  border-radius: 1em 1em 0px 0px;
  position: absolute;
  background: var(--light-color);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr;
  padding: 0px 2em;
`;

const ButtonLink = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: -1px 2px 4px rgba(0, 44, 65, 0.52);
  border-radius: 8px;
  width: 3.43em;
  height: 3.68em;
  background: var(--light-color);
`;

const TopMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;

  a {
    font-family: "Kometa";
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    text-align: center;
    text-decoration: none;
    width: 120px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: -1px 2px 4px rgba(0, 44, 65, 0.52);
    border-radius: 8px;
    font-weight: bold;
  }

  a.active {
    background: var(--secondary-color);
    font-weight: 500;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    font-weight: bold;
  }
`;

const InfoProfile = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  color: var(--light-color);
  padding: 1em 3em;

  .info-profile-top {
    .info-person {
      display: flex;
      column-gap: 1.5em;
      h1 {
        font-family: "Gilroy";
      }

      .greet-user {
        display: flex;
        align-items: flex-end;
      }
    }
    .description-section {
      font-family: "Kometa";
      font-weight: 200;
      font-style: normal;
      font-size: 1.1em;
      margin-top: 0.4em;
      opacity: 0.6;
    }
  }

  .info-profile-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info-available {
      span {
        font-weight: 200;
        opacity: 0.6;
      }
    }

    .info-pawn-aprobe {
      text-align: right;
      span {
        font-weight: 200;
        opacity: 0.6;
      }
    }
  }
`;

const CircleAvatar = styled.div`
  width: 48px;
  height: 48px;
  position: relative;
  border: 1px solid var(--light-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* backdrop-filter: blur(2px); */
  svg {
    position: relative;
    top: 0px;
    width: 28px;
  }
`;

const InputRange = styled.input`
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  width: 100%;
  max-width: 80vw;
  border-radius: 2px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    var(--secondary-color) ${(props) => `${props.valueRange}%`},
    var(--light-color) 0
  );

  ::-webkit-slider-thumb {
    opacity: 0;
  }
  ::before {
    width: ${(props) => `${props.valueRange}%`};
    background-color: var(--C-MAIN);

    filter: blur(8px);
    opacity: 0.8;
  }
`;

const ContainerAvatar = styled.div`
  position: relative;
  .level-user {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 5px;
    font-size: 0.9em;
    bottom: 50px;
    color: var(--light-color);
    opacity: 0.6;
  }
`;

const Home = (props) => {
  const { callGlobalActionApi, setDataUserProcess, dataProfile, dataRegister } =
    props;

  const [detailProcess, setDetailProcess] = useState({});

  const handleGetName = (objProfile) => {
    let name = "";
    if (isEmpty(objProfile) === false) {
      const nameComplete = dataProfile.profileInformation?.name;
      const splitName = nameComplete.split(" ");
      name = `${isNil(splitName[0]) === false ? splitName[0] : ""} ${
        isNil(splitName[1]) === false ? splitName[1] : ""
      }`;
    }
    return name;
  };

  const handlerStatusProcess = (idStatus) => {
    let statusText = "";
    if (idStatus === 1) {
      statusText = "Validando crédito";
    }
    if (idStatus === 2) {
      statusText = "Crédito pre aprobado";
    }
    if (idStatus === 3) {
      statusText = "Crédito aprobado";
    }
    return statusText;
  };

  const handleGetProcessById = async (id) => {
    try {
      const response = await callGlobalActionApi(
        {},
        id,
        API_CONSTANTS.PROCESS.GET_PROCESS_BY_ID,
        "GET",
        false
      );
      setDetailProcess(response);
      setDataUserProcess(response);
    } catch (error) {
      window.alert("Error al obtener los procesos");
    }
  };

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  const handlePercentAvailable = (amounAvailable, amountApproved) => {
    const percent = (amounAvailable * 100) / amountApproved;

    return percent;
  };

  const hanldeGetIconAvatar = (level) => {
    let icon = <></>;
    if (level === 1) {
      icon = (
        <svg
          width={33}
          height={33}
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="13.8" y={21} width={4} height="7.6" fill="#D2B48C" />
          <path d="M13.8 21H15.8V29H13.8V25V21Z" fill="#BF8970" />
          <path
            d="M26.9991 4.49979L27.5094 2.4076L31.0608 2.4076C31.6265 2.4076 32.0803 2.87962 32.0533 3.44466C31.6338 12.2148 29.8673 14.1016 25.5 14.5L25.9995 12.4998C28.7733 10.9444 29.2432 9.31329 29.5 4.5L26.9991 4.49979Z"
            fill="#D2B48C"
          />
          <path
            d="M5.09644 4.27982L4.58618 2.18763L1.03481 2.18763C0.46913 2.18763 0.0153012 2.65965 0.042324 3.22469C0.461755 11.9948 2.22824 13.8816 6.59558 14.28L6.09605 12.2798C3.32225 10.7245 2.85242 9.09332 2.59558 4.28003L5.09644 4.27982Z"
            fill="#BF8970"
          />
          <path
            d="M16 21.9832C9.27863 21.9901 4.64056 18.1899 4.03143 1.03471C4.01148 0.472849 4.46348 -2.38416e-07 5.0257 -2.38416e-07H26.9204C27.5028 -2.38416e-07 27.9618 0.502115 27.9144 1.08259C26.6315 16.7969 24.3453 22.3482 16 21.9832Z"
            fill="#D2B48C"
          />
          <path
            d="M18.5034 20.2499C20.6417 17.6476 22.3746 20.2058 22.6374 3.5H24.3695C24.5527 15.9892 22.9768 18.9556 18.5034 20.2499Z"
            fill="white"
          />
          <path
            d="M14.5 22C8.91798 21.3302 4.83849 17.7851 4.04469 1.04753C4.01782 0.481044 4.47146 0.000646965 5.03859 0.000525814L7.5 0C7.61082 12.1343 9.93646 18.4613 14.5 22Z"
            fill="#BF8970"
          />
          <path
            d="M11.5001 28H20.5001C21.6758 28.1802 22.2942 28.2204 23 30C24.3492 30.5549 24.7777 31.6229 24.6317 33H7.38149C7.37779 31.4577 7.95868 30.691 9.50014 30C9.97492 28.5056 10.4502 28.1521 11.5001 28Z"
            fill="#D2B48C"
          />
        </svg>
      );
    }
    if (level === 2) {
      icon = (
        <svg
          width={33}
          height={33}
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="13.8" y={21} width={4} height="7.6" fill="#E3E4E5" />
          <path d="M13.8 21H15.8V29H13.8V25V21Z" fill="#C0C0C0" />
          <path
            d="M26.9991 4.49979L27.5094 2.4076L31.0608 2.4076C31.6265 2.4076 32.0803 2.87962 32.0533 3.44466C31.6338 12.2148 29.8673 14.1016 25.5 14.5L25.9995 12.4998C28.7733 10.9444 29.2432 9.31329 29.5 4.5L26.9991 4.49979Z"
            fill="#E3E4E5"
          />
          <path
            d="M5.09644 4.27982L4.58618 2.18763L1.03481 2.18763C0.46913 2.18763 0.015301 2.65965 0.0423241 3.22469C0.461755 11.9948 2.22824 13.8816 6.59558 14.28L6.09605 12.2798C3.32225 10.7245 2.85242 9.09332 2.59558 4.28003L5.09644 4.27982Z"
            fill="#C0C0C0"
          />
          <path
            d="M16 21.9832C9.27863 21.9901 4.64056 18.1899 4.03143 1.03471C4.01148 0.472849 4.46348 0 5.0257 0H26.9204C27.5028 0 27.9618 0.502115 27.9144 1.08259C26.6315 16.7969 24.3453 22.3482 16 21.9832Z"
            fill="#E3E4E5"
          />
          <path
            d="M18.5034 20.2499C20.6417 17.6476 22.3746 20.2058 22.6374 3.5H24.3695C24.5527 15.9892 22.9768 18.9556 18.5034 20.2499Z"
            fill="white"
          />
          <path
            d="M14.5 22C8.91798 21.3302 4.83849 17.7851 4.04469 1.04753C4.01782 0.481045 4.47146 0.000646591 5.03859 0.000526428L7.5 0C7.61082 12.1343 9.93646 18.4613 14.5 22Z"
            fill="#C0C0C0"
          />
          <path
            d="M11.5001 28H20.5001C21.6758 28.1802 22.2942 28.2204 23 30C24.3492 30.5549 24.7777 31.6229 24.6317 33H7.38149C7.37779 31.4577 7.95868 30.691 9.50014 30C9.97492 28.5056 10.4502 28.1521 11.5001 28Z"
            fill="#E3E4E5"
          />
        </svg>
      );
    }
    if (level === 3) {
      icon = (
        <svg
          width={33}
          height={33}
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="13.8" y={21} width={4} height="7.6" fill="#FFD700" />
          <path d="M13.8 21H15.8V29H13.8V25V21Z" fill="#FFA500" />
          <path
            d="M26.9991 4.49979L27.5094 2.4076L31.0608 2.4076C31.6265 2.4076 32.0803 2.87962 32.0533 3.44466C31.6338 12.2148 29.8673 14.1016 25.5 14.5L25.9995 12.4998C28.7733 10.9444 29.2432 9.31329 29.5 4.5L26.9991 4.49979Z"
            fill="#FFD700"
          />
          <path
            d="M5.09644 4.27982L4.58618 2.18763L1.03481 2.18763C0.46913 2.18763 0.015301 2.65965 0.0423241 3.22469C0.461755 11.9948 2.22824 13.8816 6.59558 14.28L6.09605 12.2798C3.32225 10.7245 2.85242 9.09332 2.59558 4.28003L5.09644 4.27982Z"
            fill="#FFA500"
          />
          <path
            d="M16 21.9832C9.27863 21.9901 4.64056 18.1899 4.03143 1.03471C4.01148 0.472849 4.46348 0 5.0257 0H26.9204C27.5028 0 27.9618 0.502115 27.9144 1.08259C26.6315 16.7969 24.3453 22.3482 16 21.9832Z"
            fill="#FFD700"
          />
          <path
            d="M18.5034 20.2499C20.6417 17.6476 22.3746 20.2058 22.6374 3.5H24.3695C24.5527 15.9892 22.9768 18.9556 18.5034 20.2499Z"
            fill="white"
          />
          <path
            d="M14.5 22C8.91798 21.3302 4.83849 17.7851 4.04469 1.04753C4.01782 0.481045 4.47146 0.000646591 5.03859 0.000526428L7.5 0C7.61082 12.1343 9.93646 18.4613 14.5 22Z"
            fill="#FFA500"
          />
          <path
            d="M11.5001 28H20.5001C21.6758 28.1802 22.2942 28.2204 23 30C24.3492 30.5549 24.7777 31.6229 24.6317 33H7.38149C7.37779 31.4577 7.95868 30.691 9.50014 30C9.97492 28.5056 10.4502 28.1521 11.5001 28Z"
            fill="#FFD700"
          />
        </svg>
      );
    }

    return icon;
  };

  useEffect(() => {
    if (isEmpty(dataProfile) === false) {
      handleGetProcessById(dataProfile.idProcesses);
      window.Intercom("boot", {
        app_id: "otekw0v8",
        email: dataProfile.email,
        name: dataProfile.profileInformation.name,
        user_id: dataProfile.idSystemUser,
      });
    }
  }, [dataProfile]);

  return (
    <Container>
      <HeaderComponent type="dark" />
      <Main>
        <DashboardInfo>
          <div className="backround-img">
            <img src={BackDark} alt="" />
          </div>
          <InfoProfile>
            <div className="info-profile-top">
              <div className="info-person">
                <ContainerAvatar>
                  <div className="level-user">
                    <span>Nivel {dataProfile.level}</span>
                  </div>
                  <CircleAvatar>
                    {hanldeGetIconAvatar(
                      isNil(dataProfile) === false &&
                        isNil(dataProfile.level) === false
                        ? dataProfile.level
                        : 1
                    )}
                  </CircleAvatar>
                </ContainerAvatar>
                <div className="greet-user">
                  <h1>¡Hola {handleGetName(dataProfile)}!</h1>
                </div>
              </div>
              <div className="description-section">
                <span>Aqui puedes ver tu balance disponible</span>
              </div>
            </div>
            <div className="info-profile-bottom">
              <div className="info-available">
                <span>Disponible</span>
                <h2>
                  {detailProcess.idStatus !== 1
                    ? parseFormatCurrency(
                        detailProcess.amountAvailable < 0
                          ? 0
                          : detailProcess.amountAvailable,
                        2,
                        2
                      )
                    : "$0.00"}
                </h2>
                <div>
                  <InputRange
                    valueRange={handlePercentAvailable(
                      detailProcess.amountAvailable,
                      detailProcess.amountApprovedCredit
                    )}
                    type="range"
                    name=""
                    id=""
                  />
                </div>
              </div>
              <div className="info-pawn-aprobe">
                <span>{handlerStatusProcess(detailProcess.idStatus)}</span>
                <h2>
                  {detailProcess.idStatus !== 1
                    ? parseFormatCurrency(
                        detailProcess.amountApprovedCredit,
                        2,
                        2
                      )
                    : "$0.00"}
                </h2>
              </div>
            </div>
          </InfoProfile>
        </DashboardInfo>
        <div className="container-history">
          <ContainInfo>
            <TopMain>
              <NavContainer>
                <NavLink to="/home/creditos" end>
                  <span>Créditos</span>
                </NavLink>
                <NavLink to="/home/pagos">
                  <span>Pagos</span>
                </NavLink>
              </NavContainer>
            </TopMain>
            <SlideRoutes>
              <Route path="/" element={<Credits />} />
              <Route path="/creditos" element={<Credits />} />
              <Route path="/pagos" element={<Payments />} />
            </SlideRoutes>
          </ContainInfo>
        </div>
      </Main>
      {/* <FooterComponent>
        <>
          <ButtonLink>
            <img src={UserConfig} alt="user-settings" />
          </ButtonLink>
          <ButtonLink>
            <img src={IconHome} alt="user-home" />
          </ButtonLink>
          <ButtonLink>
            <img src={IconGuartante} alt="user-credits" />
          </ButtonLink>
        </>
      </FooterComponent> */}
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataProfile, dataRegister } = state;
  return {
    dataProfile: dataProfile.dataProfile,
    dataRegister: dataRegister.dataRegister,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserProcess: (data: object) => dispatch(setDataUserProcess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import styled from "styled-components";
import QRCode from "react-qr-code";
import IconInit from "../../assets/img/swip-logo-init.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr;
  padding: 1em 2em;

  .top-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      span {
        font-size: 0.8em;
      }
    }
    .container-qr {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1em;
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  margin-top: 2em;
  .terms-conditions {
    font-size: 0.7em;
    color: var(-primary-color);
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .buttons-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .option-init {
    text-align: center;
    margin-top: 1em;
    font-size: 0.8em;
    color: var(--secondary-color);
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.4em;
  width: 100%;
  font-size: 1em;
`;

const PrincipalPage = () => {
  const navigate = useNavigate();

  const [isNavigator, setIsNavigator] = useState(false);

  useEffect(() => {
    let navegador = navigator.userAgent;
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsNavigator(false);
    } else {
      setIsNavigator(true);
    }
  }, []);

  return (
    <Container>
      <div className="top-container">
        <div>
          <img src={IconInit} alt="Icono Swip inicial" />
        </div>
      </div>
      <div className="bottom-container">
        <div className="title-container">
          <h3>¡Tu linea de Crédito Revolvente!</h3>
          <span>
            Obten una linea de credito revolvente con la garantia de tu auto en
            minutos
          </span>
        </div>
        {isNavigator === true ? (
          <div className="container-qr">
            <div>
              Para continuar, por favor escanea el siguiente QR desde un
              dispositivo móvil
            </div>
            <div>
              <QRCode value={window.location.hostname} />
            </div>
          </div>
        ) : (
          <ButtonsGroup>
            <div className="buttons-actions">
              <ButtonNext onClick={() => navigate("/registro")}>
                Registrarme
              </ButtonNext>
            </div>
            <div className="option-init">
              <span>
                ¿Ya tienes una cuenta? <NavLink to="/login"> Ingresar</NavLink>
              </span>
            </div>
          </ButtonsGroup>
        )}
      </div>
    </Container>
  );
};

export default PrincipalPage;

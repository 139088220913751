import { produce } from "immer";
import GLOBAL_CONSTANTS from "../constants/globalConstants";

const initialDataProfile = { default: "" };

const dataProfile = (state = initialDataProfile, action) => {
  return produce(state, (draft) => {
    const draftState = draft;
    switch (action.type) {
      case GLOBAL_CONSTANTS.SET_DATA_USER_PROFILE:
        draftState.dataProfile = action.dataProfile;
        break;
      default:
        return state;
    }
  });
};

export { dataProfile };

import styled from "styled-components";
import { useEffect, useState } from "react";
import SlideRoutes from "react-slide-routes";
import { Route, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import HeaderComponent from "../../components/header";
import BackDark from "../../assets/img/swip-back-dark.png";
import BackDarkBtn from "../../assets/img/swip-back-dark-btm.png";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import UploadDocuments from "./uploadDocuments";
import EmptySection from "./subView/emptySection";
import ContextDocuments from "../../contexts/contextDocuments";
import { setDataUserRegister } from "../../utils/dispatchs/userRegisterDispatch";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const Documents = (props) => {
  const {
    callGlobalActionApi,
    path,
    dataRegister,
    flow_document_type,
    to,
    setDataUserRegister,
  } = props;

  const navigate = useNavigate();

  const [flowScreenDocument, setFlowScreenDocument] = useState([]);

  const handleGetFlowScreenDocument = async () => {
    try {
      const response = await callGlobalActionApi(
        {
          idUserType: dataRegister.idUserType,
          idFlowDocumentType: flow_document_type,
        },
        null,
        API_CONSTANTS.DOCUMENT.FLOW_SCREEN_DOCUMENT,
        "POST",
        false
      );
      setFlowScreenDocument(response.screens);
      if (flow_document_type === 1) {
        setDataUserRegister({
          ...dataRegister,
          idCarDocuments: crypto.randomUUID(),
        });
      }
      navigate(`${path}${response.screens[0].idScreen}`);
    } catch (error) {
      console.log("error1",error);
      window.alert(error);
    }
  };

  useEffect(() => {
    handleGetFlowScreenDocument();
    window.Intercom("update");
  }, []);

  return (
    <Container>
      <HeaderComponent type="light" />
      <Main>
        <div className="backround-img-top">
          <img src={BackDark} alt="" />
        </div>
        <div className="backround-img-btm">
          <img src={BackDarkBtn} alt="" />
        </div>
        <ContextDocuments.Provider
          value={{ idFlowDocumentType: flow_document_type }}
        >
          <SlideRoutes>
            <Route path="/" element={<EmptySection />} />
            <Route
              path=":idScreen"
              element={
                <UploadDocuments
                  flowScreenDocument={flowScreenDocument}
                  path={path}
                  to={to}
                />
              }
            />
          </SlideRoutes>
        </ContextDocuments.Provider>
        {/* {flowScreenDocument.map((step, index) => {
          const pathDocuments = path;
          const isLast = isUndefined(flowScreenDocument[index + 1]) === true;
          const nextScreen = isLast
            ? to
            : `${pathDocuments}${flowScreenDocument[index + 1].idScreen}`;

          if (
            idScreen === step.idScreen &&
            idScreen !== GLOBAL_CONSTANTS.SCREEN_SELFIE
          ) {
            return (
              <ViewDocumentGeneric
                key={index}
                {...step}
                nextScreen={nextScreen}
                isLast={isLast}
              />
            );
          }

          if (idScreen === step.idScreen) {
            return (
              <ViewSelfie
                key={index}
                {...step}
                nextScreen={nextScreen}
                isLast={isLast}
              />
            );
          }
        })} */}
      </Main>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataRegister } = state;
  return {
    dataRegister: dataRegister.dataRegister,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserRegister: (data) => dispatch(setDataUserRegister(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);

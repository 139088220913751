import { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { NavLink, Route, useLocation } from "react-router-dom";
import SlideRoutes from "react-slide-routes";
import HeaderComponent from "../../components/header";
import BackDark from "../../assets/img/swip-back-dark.png";
import UpPercent from "../../assets/img/swip-increment-percent.png";
import CreditCard from "../../assets/img/swip-credit-card.png";
import CreditPlus from "../../assets/img/swip-credit-plus.png";
import PayMonth from "./subView/payMonth";
import PayComplete from "./subView/payComplete";
import PayContributeCapital from "./subView/payContributeCapital";

const NavContainer = styled.nav`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;

  a {
    font-family: "Kometa";
    padding: 5px;
    background: var(--light-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--secondary-color);
    text-align: center;
    text-decoration: none;
    width: 10em;
    height: 13em;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: -1px 2px 4px rgba(0, 44, 65, 0.52);
    border-radius: 8px;
    font-weight: 300;
    font-size: 0.9em;
    span {
      margin-top: 2em;
    }
  }

  a.active {
    background: var(--secondary-color);
    color: var(--primary-color);
    font-weight: 500;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    font-weight: bold;
  }
`;

const Main = styled.main`
  display: grid;
  grid-template-rows: ${(props) => (props.type === 1 ? "1fr 1fr" : "1fr 2fr")};
  height: 100%;

  .top-main {
    z-index: 0;
    position: relative;
    overflow: hidden;
    padding: 2em 1em;
    .backround-img {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 0;
      width: 100%;
    }
    .title-section {
      text-align: center;
      font-family: "Kometa";
      font-weight: bold;
    }
  }

  .bottom-main {
    padding: 1em;
    background: var(--primary-color);
    border-radius: 2em 2em 0px 0px;
  }
`;

const Container = styled.div`
  font-size: 16px;
  height: 100vh;
  display: grid;
  grid-template-rows: var(--size-header) 1fr;

  @media screen and (max-width: 520px) {
    font-size: 12px;
  }
  @media screen and (max-width: 380px) {
    font-size: 10px;
  }
`;

const SelectPayments = (props) => {
  const { dataLoan } = props;
  const location = useLocation();
  const [typeScreen, setTypeScreen] = useState(1);

  useEffect(() => {
    if (location.pathname === "/selecciona-pago/aportar-capital") {
      setTypeScreen(2);
    } else {
      setTypeScreen(1);
    }
  }, [location]);

  return (
    <Container>
      <HeaderComponent type="light" />
      <Main type={typeScreen}>
        <div className="top-main">
          <div className="title-section">
            <h2>¿Cómo quieres realizar tu pago?</h2>
          </div>
          <NavContainer>
            <NavLink to="/selecciona-pago" end>
              <img src={UpPercent} alt="swip" />
              <span>Pago mensual de intereses</span>
            </NavLink>
            <NavLink to="/selecciona-pago/liquidar" end>
              <img src={CreditCard} alt="swip" />
              <span>Liquidar</span>
            </NavLink>
            <NavLink to="/selecciona-pago/aportar-capital" end>
              <img src={CreditPlus} alt="swip" />
              <span>Aportar a capital</span>
            </NavLink>
          </NavContainer>
          <div className="backround-img">
            <img src={BackDark} alt="" />
          </div>
        </div>
        <div className="bottom-main">
          <SlideRoutes>
            <Route path="/" element={<PayMonth idPaymentType={1} />} />
            <Route
              path="/liquidar"
              element={<PayComplete idPaymentType={2} />}
            />
            <Route
              path="/aportar-capital"
              element={<PayContributeCapital idPaymentType={3} />}
            />
          </SlideRoutes>
        </div>
      </Main>
      {/* <FooterComponent type="dark">
        <NavCenterActionComponent
          to="/informacion-pago"
          onClick={(nav) => {
            nav("/informacion-pago");
          }}
          label="Siguiente"
          type="light"
        />
      </FooterComponent> */}
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataLoan } = state;
  return {
    dataLoan: dataLoan.dataLoan,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPayments);

import { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import BackDark from "../../assets/img/swip-back-dark.png";
import BackDarkBtn from "../../assets/img/swip-back-dark-btm.png";
import HeaderComponent from "../../components/header";
import { useOnChangeInput } from "../../hooks";
import ENVIROMENT from "../../utils/constants/enviroments";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import { callGlobalActionApi } from "../../utils/actions/actions";
import Loader from "../../components/loader";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  .buttons-actions {
    margin-top: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  width: 70%;
  font-size: 1em;
`;

const InfoAutoForm = (props) => {
  const { callGlobalActionApi, dataRegister } = props;
  const navigate = useNavigate();
  const initialState = {
    brand: "",
    model: "",
    type: "",
    transmissionType: "",
    color: "",
    year: "",
    mileage: "",
    amountPrice: 0,
    numberPlates: "",
    isInsured: false,
  };

  const [dataForm, handlerOnChange] = useOnChangeInput(initialState);
  const [isLoading, setIsLoading] = useState(false);
  
  const handlerSetDataInfoCar = async () => {
    try {
      setIsLoading(true);
      const response = await callGlobalActionApi(
        { ...dataForm, idSystemUser: dataRegister.idSystemUser },
        null,
        API_CONSTANTS.CAR_INFO.SET_NEW_CAR_INFO,
        "POST",
        false
      );

      await callGlobalActionApi(
        {
          idCarInformation: response.idCarInformation,
          idCarDocuments: dataRegister.idCarDocuments,
          idPersonalDocuments: dataRegister.idPersonalDocuments,
          idLoans: dataRegister.idLoans,
          idProcesses: dataRegister.idProcesses,
          idSystemUser: response.idSystemUser,
        },
        null,
        API_CONSTANTS.PROCESS.CREATE_PROCESS,
        "POST",
        false
      );
      await fetch(
        `${ENVIROMENT}${API_CONSTANTS.USER.USER_PROGRESS}${response.idSystemUser}?screen=4`
      );
      setIsLoading(false);
      navigate("/finalizar-proceso");
    } catch (error) {
      setIsLoading(false);
      window.alert(error);
    }
  };

  // await fetch(
  //   `${ENVIROMENT}${API_CONSTANTS.USER.USER_PROGRESS}${dataRegister.idSystemUser}?screen=2`
  // );
  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="light" />
      <Main>
        <div className="backround-img-top">
          <img src={BackDark} alt="" />
        </div>
        <div className="backround-img-btm">
          <img src={BackDarkBtn} alt="" />
        </div>
        <div className="main-info">
          <h3>Información de tu auto</h3>
          <p>Algunos campos son requeridos</p>
        </div>
        <div>
          <Box component="form" noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                marginTop: "2em",
              }}
            >
              <TextField
                required
                id="brand"
                name="brand"
                label="Marca"
                onChange={handlerOnChange}
                defaultValue=""
              />
              <TextField
                required
                id="model"
                name="model"
                label="Modelo"
                onChange={handlerOnChange}
                defaultValue=""
              />
              <TextField
                required
                id="year"
                name="year"
                label="Año"
                onChange={handlerOnChange}
                defaultValue=""
              />
              <TextField
                required={false}
                id="kilometers"
                name="mileage"
                label="Kilometraje"
                onChange={handlerOnChange}
                defaultValue=""
              />
              <TextField
                required={false}
                id="numberPlates"
                name="numberPlates"
                label="Número de placas"
                onChange={handlerOnChange}
                defaultValue=""
              />
              <FormControl>
                <FormLabel
                  style={{
                    color: "var(--primary-color)",
                  }}
                  id="demo-row-radio-buttons-group-label"
                >
                  ¿Tu auto esta asegurado?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handlerOnChange}
                >
                  <FormControlLabel
                    value={true}
                    name="isInsured"
                    control={<Radio />}
                    label="Si"
                  />
                  <FormControlLabel
                    value={false}
                    name="isInsured"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Box>
          <ButtonsGroup>
            <div className="buttons-actions">
              <ButtonNext onClick={handlerSetDataInfoCar}>Siguiente</ButtonNext>
            </div>
          </ButtonsGroup>
        </div>
      </Main>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataRegister } = state;
  return {
    dataRegister: dataRegister.dataRegister,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoAutoForm);

import { produce } from "immer";
import GLOBAL_CONSTANTS from "../constants/globalConstants";

const initialDataLoan = { default: "" };

const dataLoan = (state = initialDataLoan, action) => {
  return produce(state, (draft) => {
    const draftState = draft;
    switch (action.type) {
      case GLOBAL_CONSTANTS.SET_DATA_USER_LOAN:
        draftState.dataLoan = action.dataLoan;
        break;
      default:
        return state;
    }
  });
};

export { dataLoan };
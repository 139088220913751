import { useEffect, useState } from "react";
import { connect } from "react-redux";
import isNil from "lodash/isNil";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/header";
import BackDark from "../../assets/img/swip-back-dark.png";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import Loader from "../../components/loader";
import ENVIROMENT from "../../utils/constants/enviroments";
import { setDataUserProfile } from "../../utils/dispatchs/userProfileDispatch";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr 70px;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  z-index: 1;
  .buttons-actions {
    margin-top: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  font-size: 1em;
`;

const CardDetail = styled.div`
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  margin-top: 4em;

  .item-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .item-info {
      display: flex;
      flex-direction: column;
    }
    .item-cost {
      display: flex;
      flex-direction: column;
    }
  }
`;

const GPSCost = (props) => {
  const initialState = {
    description: [],
    gpsMonthly: 0,
    numberOfUnits: 0,
    totalGps: 0,
  };
  const { callGlobalActionApi, dataRegister, setDataUserProfile } = props;
  const [detailGps, setDetailGps] = useState(initialState);
  const [dataProcess, setDataProcess] = useState({});
  const [dataBankInfo, setDataBankInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  const handleGetProcessById = async (id) => {
    try {
      const response = await callGlobalActionApi(
        {},
        id,
        API_CONSTANTS.PROCESS.GET_PROCESS_BY_ID,
        "GET",
        false
      );
      setDataProcess(response);
    } catch (error) {
      window.alert("Error al obtener los procesos");
    }
  };

  const handleGetGPSOpeningPrice = async () => {
    try {
      const response = await callGlobalActionApi(
        {},
        dataRegister.idProcesses,
        API_CONSTANTS.LOAN.OPENING_PRICE,
        "GET",
        false
      );
      setDetailGps(response);
    } catch (error) {
      window.alert("Error al obtener los procesos");
    }
  };

  const handleCreateLoan = async (data) => {
    try {
      setIsLoading(true);
      await callGlobalActionApi(
        data,
        null,
        API_CONSTANTS.LOAN.CREATE_LOAN,
        "POST",
        false
      );
      setIsLoading(false);
      await fetch(
        `${ENVIROMENT}${API_CONSTANTS.USER.USER_PROGRESS}${dataRegister.idSystemUser}?screen=7`
      );
      const responseProfile = await callGlobalActionApi(
        {},
        dataRegister.idSystemUser,
        API_CONSTANTS.USER.USER_GET_LOGIN,
        "GET",
        false
      );

      await setDataUserProfile(responseProfile);
      navigate("/confirmado?type=pending");
    } catch (error) {
      setIsLoading(false);
      window.alert("Error al crear un prestamo");
    }
  };

  const handleGetBankInfo = async (id) => {
    try {
      const response = await callGlobalActionApi(
        {},
        id,
        API_CONSTANTS.PROCESS.GET_BANK_INFO,
        "GET",
        false
      );
      setDataBankInfo(response);
    } catch (error) {
      window.alert("Error al obtener los procesos");
    }
  };

  useEffect(() => {
    handleGetGPSOpeningPrice();
    handleGetProcessById(dataRegister.idProcesses);
    handleGetBankInfo(dataRegister.idSystemUser);
  }, []);

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="light" />
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="backround-img"
          style={{
            position: "absolute",
          }}
        >
          <img src={BackDark} alt="" />
        </div>
        <Main>
          <div className="main-info">
            <h3>Costo intalación GPS </h3>
            <p>
              El costo de la instalación más la primera mensualidad se
              descontará de tu crédito autorizado, este cobro lo verás reflejado
              en tu primer pago
            </p>
          </div>
          <CardDetail>
            {detailGps.description.map((item, index) => {
              return (
                <div className="item-card">
                  <div className="item-info">
                    <strong>Unidad {index + 1}</strong>
                    <span>
                      {item.brand} {item.model} {item.year}
                    </span>
                    <span>{item.numberPlates}</span>
                  </div>
                  <div className="item-cost">
                    <strong>{parseFormatCurrency(item.gpsMonthly)} MXN</strong>
                    <span>Iva incluido</span>
                  </div>
                </div>
              );
            })}

            <div className="item-card">
              <strong>Total {detailGps.numberOfUnits} Unidades</strong>
              <span>{parseFormatCurrency(detailGps.totalGps)} MXN</span>
            </div>
          </CardDetail>

          <ButtonsGroup>
            <div className="buttons-actions">
              <ButtonNext
                onClick={async () => {
                  const data = {
                    idBankAccount: dataBankInfo.idBankAccount,
                    idSystemUser: dataProcess.idSystemUser,
                    idProcesses: dataProcess.idProcesses,
                    idLoans: dataProcess.idLoans,
                    concept: "Costo por apertura y GPS",
                    amountMonthly: detailGps.totalGps,
                    amountLoan: detailGps.totalGps,
                    amountIva: 0,
                    amountInterest: 0,
                    amountPayOff: detailGps.totalGps,
                    idStatus: 1,
                    idLoanType: 1,
                  };
                  await handleCreateLoan(data);
                }}
              >
                Aceptar
              </ButtonNext>
            </div>
          </ButtonsGroup>
        </Main>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataRegister } = state;
  return {
    dataRegister: dataRegister.dataRegister,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserProfile: (data) => dispatch(setDataUserProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GPSCost);

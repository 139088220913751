import { useState, useCallback, useRef, useContext } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import { callSetCustomerInDocument } from "../../../utils/actions/actions";
import ContextDocuments from "../../../contexts/contextDocuments";
import Loader from "../../../components/loader";

const ContainerSelfie = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  overflow: hidden;
  .mask-video {
    position: absolute;
    z-index: 2;
    width: 70%;
    border-radius: 50%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 2px solid var(--secondary-color);
    box-shadow: -1px 0px 0px 100vw rgba(255, 255, 255, 1);
  }
`;

const ButtonShot = styled.button`
  outline: none;
  border: none;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerDocument = styled.div`
  .buttons-actions {
    margin-top: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  width: 70%;
  font-size: 1em;
`;

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user",
};

const ViewSelfie = (props) => {
  const {
    name,
    description,
    callSetCustomerInDocument,
    bucketSource,
    idDocumentType,
    idScreen,
    dataRegister,
    nextScreen,
  } = props;

  const contextDocuments = useContext(ContextDocuments);
  const { idFlowDocumentType } = contextDocuments;

  const [picture, setPicture] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const webcamRef = useRef(null);

  const navigate = useNavigate();

  const capture = useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  });

  const handlerGenerateBlobImage = async (src: string, data: object) => {
    try {
      setIsLoading(true);
      const urlObject = await fetch(src);
      const blobFile = await urlObject.blob();
      const metadata = {
        name: "selfie",
        type: blobFile.type,
        size: blobFile.size,
      };
      await callSetCustomerInDocument(
        blobFile,
        { ...data, ...metadata },
        () => {},
        "POST",
        false
      );
      setIsLoading(false);
      navigate(nextScreen);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="main-info">
      <Loader isVisible={isLoading} />
      <h3>{name}</h3>
      <p>{description}</p>
      <ContainerDocument>
        <ContainerSelfie
          onClick={(e) => {
            e.preventDefault();
            setPicture("");
          }}
        >
          <div className="mask-video"></div>
          {picture !== "" && <img src={picture} alt="preview"/>}
          {picture === "" && (
            <Webcam
              audio={false}
              ref={webcamRef}
              width="100%"
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              mirrored={true}
            />
          )}
        </ContainerSelfie>
        {picture != "" && (
          <p
            style={{
              fontSize: ".9em",
            }}
          >
            Has clic sobre la imagen si quieres intentar de nuevo
          </p>
        )}

        <div className="buttons-actions">
          {picture != "" && (
            <ButtonNext
              onClick={() => {
                handlerGenerateBlobImage(picture, {
                  bucketSource,
                  idDocumentType,
                  idScreen,
                  idSystemUser: dataRegister.idSystemUser,
                  idPersonalDocuments: dataRegister.idPersonalDocuments,
                  idUserType: dataRegister.idUserType,
                  idFlowDocumentType,
                  idCarDocuments: dataRegister.idCarDocuments,
                });
              }}
            >
              Siguiente
            </ButtonNext>
          )}
          {picture === "" && (
            <ButtonShot
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
            >
              <svg
                width={47}
                height={42}
                viewBox="0 0 47 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.3329 30.7995C27.4566 30.7995 30.7995 27.4566 30.7995 23.3329C30.7995 19.2092 27.4566 15.8662 23.3329 15.8662C19.2092 15.8662 15.8662 19.2092 15.8662 23.3329C15.8662 27.4566 19.2092 30.7995 23.3329 30.7995Z"
                  fill="#F9F9F9"
                />
                <path
                  d="M16.3333 0L12.0633 4.66667H4.66667C2.1 4.66667 0 6.76667 0 9.33333V37.3333C0 39.9 2.1 42 4.66667 42H42C44.5667 42 46.6667 39.9 46.6667 37.3333V9.33333C46.6667 6.76667 44.5667 4.66667 42 4.66667H34.6033L30.3333 0H16.3333ZM23.3333 35C16.8933 35 11.6667 29.7733 11.6667 23.3333C11.6667 16.8933 16.8933 11.6667 23.3333 11.6667C29.7733 11.6667 35 16.8933 35 23.3333C35 29.7733 29.7733 35 23.3333 35Z"
                  fill="#F9F9F9"
                />
              </svg>
            </ButtonShot>
          )}
        </div>
      </ContainerDocument>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { dataRegister } = state;
  return {
    dataRegister: dataRegister.dataRegister,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callSetCustomerInDocument: (file, data, callback, method, token) =>
    dispatch(callSetCustomerInDocument(file, data, callback, method, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSelfie);

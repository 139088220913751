import { combineReducers } from "redux";
import { purgeStoredState } from "redux-persist";
import { dataProfile } from "../utils/reducers/dataProfileReducer";
import { dataRegister } from "../utils/reducers/dataRegisterReducer";
import { dataProcess } from "../utils/reducers/dataProcessReducer";
import { dataLoan } from "../utils/reducers/dataLoanReducer";

const appReducers = combineReducers({
  dataProfile,
  dataRegister,
  dataProcess,
  dataLoan
});

const rootReducer = (state, action, persistConfig) => {
  // Reset to initialState
  if (action.type === "PURGE") {
    purgeStoredState(persistConfig);
    // Default or Current State
    return appReducers({}, action);
  }

  return appReducers(state, action);
};

export default rootReducer;

import styled from "styled-components";
import { useSearchParams, useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/header";
import { Container } from "../../assets/styles/styledGeneral";
import FooterComponent from "../../components/footer";
import NavCenterActionComponent from "../../components/navCenterAction";
import BackDark from "../../assets/img/swip-back-dark.png";

const Main = styled.main`
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--size-header) - var(--size-footer));
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 1em;

  .status-pay {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
    }
  }

  .center-img {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .title-page {
    margin: 2em 0px;
    h2 {
      font-weight: bold;
    }
  }
`;

const Payment = () => {
  const [searchParams] = useSearchParams();
  const statusPay = searchParams.get("result");

  return (
    <Container>
      <HeaderComponent type="light" />
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="backround-img"
          style={{
            position: "absolute",
          }}
        >
          <img src={BackDark} alt="" />
        </div>
        <Main>
          <div className="status-pay">
            {statusPay === "success" ? (
              <svg
                width={234}
                height={145}
                viewBox="0 0 234 145"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32 57.5V21C32 11.0589 40.0589 3 50 3H213C222.941 3 231 11.0589 231 21V90C231 99.9411 222.941 108 213 108H87"
                  stroke="#002C41"
                  strokeWidth={5}
                />
                <rect x={32} y={24} width={199} height={20} fill="#002C41" />
                <circle cx="143.5" cy="88.5" r="2.5" fill="#002C41" />
                <path
                  d="M154.5 88.5C154.5 87.1193 155.619 86 157 86H192C193.381 86 194.5 87.1193 194.5 88.5V88.5C194.5 89.8807 193.381 91 192 91H157C155.619 91 154.5 89.8807 154.5 88.5V88.5Z"
                  fill="#002C41"
                />
                <circle
                  cx={45}
                  cy={100}
                  r="42.5"
                  stroke="#00D8A9"
                  strokeWidth={5}
                />
                <path
                  d="M24.0645 101.5L31.5639 111.761"
                  stroke="#00D8A9"
                  strokeWidth={6}
                  strokeLinecap="round"
                />
                <line
                  x1="32.2364"
                  y1="112.752"
                  x2="67.3754"
                  y2="87.328"
                  stroke="#00D8A9"
                  strokeWidth={6}
                  strokeLinecap="round"
                />
              </svg>
            ) : (
              <svg
                width={234}
                height={145}
                viewBox="0 0 234 145"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32 57.5V21C32 11.0589 40.0589 3 50 3H213C222.941 3 231 11.0589 231 21V90C231 99.9411 222.941 108 213 108H87"
                  stroke="#002C41"
                  strokeWidth={5}
                />
                <rect x={32} y={24} width={199} height={20} fill="#002C41" />
                <circle cx="143.5" cy="88.5" r="2.5" fill="#002C41" />
                <path
                  d="M154.5 88.5C154.5 87.1193 155.619 86 157 86H192C193.381 86 194.5 87.1193 194.5 88.5V88.5C194.5 89.8807 193.381 91 192 91H157C155.619 91 154.5 89.8807 154.5 88.5V88.5Z"
                  fill="#002C41"
                />
                <circle
                  cx={45}
                  cy={100}
                  r="42.5"
                  stroke="#FF7676"
                  strokeWidth={5}
                />
                <line
                  x1="31.2426"
                  y1={86}
                  x2="60.9411"
                  y2="115.698"
                  stroke="#FF7676"
                  strokeWidth={6}
                  strokeLinecap="round"
                />
                <line
                  x1={30}
                  y1="115.699"
                  x2="59.6985"
                  y2={86}
                  stroke="#FF7676"
                  strokeWidth={6}
                  strokeLinecap="round"
                />
              </svg>
            )}
          </div>
          <div className="center-img">
            <h2>
              {statusPay === "success"
                ? "¡Pago exitoso!"
                : "¡Pago no realizado con éxito!"}
            </h2>
          </div>
        </Main>
      </div>
      <FooterComponent>
        <NavCenterActionComponent
          onClick={(nav) => {
            nav("/home/pagos");
          }}
          label="Continuar"
          type="light"
        />
      </FooterComponent>
    </Container>
  );
};

export default Payment;

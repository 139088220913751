import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Logo from "../assets/img/swip-icon.png";
import LogoLight from "../assets/img/swip-icon-light.png";

const Header = styled.header`
  background: ${(props) =>
    props.type === "dark" ? "var(--primary-color)" : "var(--light-color)"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
`;

const Menu = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
`;
const options = ["Cerrar sesión"];

const HeaderComponent = (props) => {
  const { type = "dark", isVisibleMenu = true } = props;
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    navigate("/logout");
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Header type={type}>
      <div>
        <img src={type === "dark" ? Logo : LogoLight} alt="logo-swip" />
      </div>
      <div>
        {isVisibleMenu && (
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
          >
            <Menu onClick={handleToggle}>
              {type === "dark" && (
                <svg
                  width={23}
                  height={13}
                  viewBox="0 0 23 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x={5} y={10} width={18} height={3} fill="white" />
                  <rect x={5} width={18} height={3} fill="white" />
                  <rect y={5} width={18} height={3} fill="white" />
                </svg>
              )}
              {type === "light" && (
                <svg
                  width={23}
                  height={13}
                  viewBox="0 0 23 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x={5}
                    y={10}
                    width={18}
                    height={3}
                    fill="var(--primary-color)"
                  />
                  <rect
                    x={5}
                    width={18}
                    height={3}
                    fill="var(--primary-color)"
                  />
                  <rect
                    y={5}
                    width={18}
                    height={3}
                    fill="var(--primary-color)"
                  />
                </svg>
              )}
            </Menu>
          </ButtonGroup>
        )}
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Header>
  );
};

export default HeaderComponent;

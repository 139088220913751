import styled from "styled-components";

const Container = styled.div`
  font-size: 16px;
  height: 100vh;
  display: grid;
  grid-template-rows: var(--size-header) 1fr var(--size-footer);

  @media screen and (max-width: 520px) {
    font-size: 12px;
  }
  @media screen and (max-width: 380px) {
    font-size: 10px;
  }
`;

export { Container };

import styled from "styled-components";
import { connect } from "react-redux";
import isNil from "lodash/isNil";
import { useNavigate } from "react-router-dom";
import { setDataUserLoan } from "../../../utils/dispatchs/userLoanDispatch";

const Container = styled.div`
  color: var(--light-color);
  .amount-pay {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    padding: 0px 2em;
    font-size: 1.1em;
    color: var(--secondary-color);
  }
  .title-verify {
    padding: 1em 0px;
  }
`;

const ContentInfo = styled.div`
  background: var(--primary-color);
  border-radius: 0.8em;
  padding: 1em;
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  .content-request-tp {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    border-top: 2px solid var(--light-color);
    border-bottom: 1px solid var(--light-color);
    .left-info {
    }
    .right-info {
      span {
        opacity: 0.6;
        font-weight: 100;
      }
    }
    div {
      display: flex;
      row-gap: 10px;
      flex-direction: column;
    }
  }
  .content-request-bm {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    span {
      color: var(--secondary-color);
      font-weight: 500;
    }
  }
`;

const ContainerButton = styled.div`
  margin-top: 1em;
  display: flex;
  align-items: center;

  button {
    border: none;
    width: 100%;
    background: var(--light-color);
    color: var(--primary-color);
    padding: 1em 0px;
    border-radius: 0.8em;
  }
`;

const PayComplete = (props) => {
  const { dataLoan, idPaymentType, setDataUserLoan } = props;

  const navigation = useNavigate();

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  return (
    <Container>
      <div className="title-verify">
        <h3>Liquidar</h3>
      </div>
      <div className="amount-pay">
        <span>{parseFormatCurrency(dataLoan.amountPayOff, 0, 2)}</span>
        <span>{dataLoan.concept}</span>
      </div>
      <ContentInfo>
        <div className="content-request-tp">
          <div className="left-info">
            <span>Préstamo</span>
            <span>Interés</span>
            <span>Iva</span>
          </div>
          <div className="right-info">
            <span>{parseFormatCurrency(dataLoan.amountLoan, 0, 2)}</span>
            <span>{parseFormatCurrency(dataLoan.amountInterest, 0, 2)}</span>
            <span>{parseFormatCurrency(dataLoan.amountIva, 0, 2)}</span>
          </div>
        </div>
        <div className="content-request-bm">
          <div>Total:</div>
          <span>{parseFormatCurrency(dataLoan.amountPayOff, 0, 2)}</span>
        </div>
      </ContentInfo>
      <ContainerButton>
        <button
          onClick={() => {
            setDataUserLoan({
              ...dataLoan,
              amountToPay: dataLoan.amountPayOff,
            });
            navigation(`/informacion-pago?payment=${idPaymentType}`);
          }}
        >
          Siguiente
        </button>
      </ContainerButton>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataLoan } = state;
  return {
    dataLoan: dataLoan.dataLoan,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDataUserLoan: (data) => dispatch(setDataUserLoan(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayComplete);

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const NavContainerAction = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    font-family: "Kometa";
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-color);
    background: var(--primary-color);
    text-align: center;
    text-decoration: none;
    width: 50%;
    height: 40px;
    border-radius: 8px;
    font-weight: 300;
    font-style: normal;
    outline: none;
    border: none;
    cursor: pointer;
  }
`;

const NavContainerActionLight = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    font-family: "Kometa";
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    background: var(--light-color);
    text-align: center;
    text-decoration: none;
    width: 50%;
    height: 40px;
    border-radius: 8px;
    font-weight: 500;
    font-style: normal;
    box-shadow: -1px 2px 4px rgba(0, 44, 65, 0.52);
    outline: none;
    border: none;
    cursor: pointer;
  }
`;

const NavContainerActionPrimary = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    font-family: "Kometa";
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-color);
    background: var(--secondary-color);
    text-align: center;
    text-decoration: none;
    width: 50%;
    height: 40px;
    border-radius: 8px;
    font-weight: 500;
    font-style: normal;
    box-shadow: -1px 2px 4px rgba(0, 44, 65, 0.52);
    outline: none;
    border: none;
    cursor: pointer;
  }
`;

const NavCenterActionComponent = (props) => {
  const { label = "", type = "dark", onClick = () => {} } = props;
  const navigate = useNavigate();

  return (
    <>
      {type === "dark" && (
        <NavContainerAction>
          <button
            onClick={() => {
              onClick(navigate);
            }}
          >
            <span>{label}</span>
          </button>
        </NavContainerAction>
      )}

      {type === "light" && (
        <NavContainerActionLight>
          <button
            onClick={() => {
              onClick(navigate);
            }}
          >
            <span>{label}</span>
          </button>
        </NavContainerActionLight>
      )}

      {type === "primary" && (
        <NavContainerActionPrimary>
          <button
            onClick={() => {
              onClick(navigate);
            }}
          >
            <span>{label}</span>
          </button>
        </NavContainerActionPrimary>
      )}
    </>
  );
};

export default NavCenterActionComponent;

import styled, { keyframes } from "styled-components";

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0;
    transform: translateY(-5px) scale(0.85);
  }
  50% {
    opacity: 1;
    transform: translateY(0) scale(0.9);
  }
`;

const rotate = keyframes`
   to {
    transform: rotate(360deg);
  }
`;

const SectionLoader = styled.div`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1301;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);

  .loader__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    position: relative;
    .loader__logo_sw {
      width: 90%;
      position: absolute;
      animation: ${fadeInOut} 1.5s infinite;
    }
    .spinner {
      position: absolute;
      animation: ${rotate} 1.4s linear infinite;
    }
  }
`;

const Loader = (props) => {
  const { isVisible } = props;
  return (
    <SectionLoader isVisible={isVisible}>
      <div className="loader__spinner">
        <svg
          className="loader__logo_sw"
          width={350}
          height={226}
          viewBox="0 0 35 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_34_761)">
            <path
              d="M21.479 2.321C20.4294 3.34822 15.5791 8.10413 14.4677 9.17804L7.50444 15.955C7.31921 16.1351 7.16142 16.3219 7.01735 16.522C6.94875 16.6221 6.88015 16.7221 6.82526 16.8222C6.64003 17.1357 6.50282 17.4625 6.41364 17.8027C6.35876 18.0295 6.31073 18.2563 6.29015 18.4897C6.26271 18.7432 6.26271 18.99 6.29015 19.2435C6.29701 19.3769 6.31759 19.5103 6.34504 19.637C6.36562 19.7371 6.3862 19.8305 6.41364 19.9305C6.43422 20.0172 6.46166 20.0973 6.4891 20.184C6.51654 20.2707 6.54399 20.3507 6.58515 20.4308C6.61259 20.5108 6.64689 20.5909 6.68805 20.6643C6.7498 20.791 6.8184 20.9177 6.90073 21.0445C6.94875 21.1178 6.99677 21.1912 7.04479 21.2579C7.182 21.4447 7.33293 21.6181 7.50444 21.7849L13.027 27.1544C13.027 27.1544 14.0355 27.9549 13.9669 29.0888C13.9532 29.8626 12.684 31.0232 11.6824 32.0971C11.0993 32.7241 10.5299 33.4178 10.2692 33.7447L1.81719 28.7886C1.00081 28.3084 0.5 27.4479 0.5 26.5208V10.879C0.5 9.91844 1.02825 9.03796 1.88579 8.57104L16.0182 0.813517C16.8414 0.366609 17.843 0.359939 18.6731 0.813517L21.479 2.32767V2.321Z"
              fill="#01FE4A"
            />
            <path
              d="M34.5 10.892V27.0874C34.5 28.048 33.9649 28.9351 33.1005 29.402L18.7143 37.1395C17.8636 37.5998 16.8277 37.5798 15.9907 37.0928L13.3152 35.5253C14.063 34.7916 19.2768 29.6822 20.4362 28.5549L27.3995 21.7846C27.9484 21.251 28.312 20.6106 28.4903 19.9302C28.5178 19.8302 28.5383 19.7368 28.5589 19.6367C28.5795 19.5033 28.6001 19.3766 28.6138 19.2432C28.6413 18.9897 28.6413 18.7429 28.6138 18.4895C28.5932 18.256 28.5452 18.0292 28.4903 17.8024C28.312 17.1287 27.9484 16.4884 27.3995 15.9548L21.8769 10.5785C21.8769 10.5785 20.8685 9.78476 20.9371 8.65081C20.9508 7.87039 22.2199 6.70976 23.2216 5.64252C23.7978 5.02885 24.3535 4.34848 24.6211 4.0083L33.1005 8.57744C33.9649 9.03768 34.5 9.92483 34.5 10.892Z"
              fill="#01FE4A"
            />
          </g>
          <defs>
            <clipPath id="clip0_34_761">
              <rect
                width={34}
                height={37}
                fill="white"
                transform="translate(0.5 0.473145)"
              />
            </clipPath>
          </defs>
        </svg>

        {/* <svg
          className="loader__logo_sw"
          width={380}
          height={246}
          viewBox="0 0 380 246"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M271.196 165.323L310.024 222.912C315.659 231.272 328.636 230.488 333.142 221.518L361.115 165.814C363.049 161.96 362.85 157.432 360.584 153.752L280.223 23.2663C277.831 19.3822 273.484 17 268.788 17H108.338C103.59 17 99.2033 19.4356 96.8291 23.3891L18.7792 153.401C16.4523 157.276 16.405 162.04 18.6537 165.957L50.1687 220.87C55.3186 229.844 68.8104 229.626 73.6458 220.493L104.356 162.484"
            stroke="#09CA97"
            strokeWidth={34}
          />
          <path
            d="M125.108 145.436C126.428 143.249 128.861 141.903 131.492 141.903H242.919C245.368 141.903 247.657 143.071 249.03 145.02L270.034 174.827C273.354 179.539 269.843 185.903 263.923 185.903H113.488C107.796 185.903 104.247 179.975 107.104 175.243L125.108 145.436Z"
            fill="#09CA97"
          />
        </svg> */}
        <svg
          className="spinner"
          width="174px"
          height="174px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="path"
            fill="transparent"
            strokeWidth={2}
            cx={33}
            cy={33}
            r={30}
            stroke="url(#gradient)"
          />
          <linearGradient id="gradient">
            <stop offset="50%" stopColor="#01FE4A" stopOpacity={1} />
            <stop offset="65%" stopColor="#01FE4A" stopOpacity=".5" />
            <stop offset="100%" stopColor="#01FE4A" stopOpacity={0} />
          </linearGradient>
          <svg
            className="spinner-dot dot"
            width="5px"
            height="5px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
            x={37}
            y="1.5"
          >
            <circle className="path" fill="#01FE4A" cx={33} cy={33} r={30} />
          </svg>
        </svg>
      </div>
    </SectionLoader>
  );
};

export default Loader;

import { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import BackDark from "../../assets/img/swip-back-dark.png";
import BackDarkBtn from "../../assets/img/swip-back-dark-btm.png";
import HeaderComponent from "../../components/header";
import ENVIROMENT from "../../utils/constants/enviroments";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import { useOnChangeInput } from "../../hooks";
import { callGlobalActionApi } from "../../utils/actions/actions";
import Loader from "../../components/loader";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  overflow-y: scroll;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  .buttons-actions {
    margin-top: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  width: 70%;
  font-size: 1em;
`;

const InfoUserForm = (props) => {
  const { callGlobalActionApi, dataRegister } = props;
  const navigate = useNavigate();
  const initialState = {
    name: "",
    dateOfBirth: "",
    curp: "",
    rfc: "",
    numberId: "",
    street: "",
    neighborhood: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
  };
  const [dataForm, handlerOnChange, setDataForm] =
    useOnChangeInput(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const handlerSetDataInfoProfile = async () => {
    try {
      setIsLoading(true);
      await callGlobalActionApi(
        {
          ...dataForm,
          idSystemUser: dataRegister.idSystemUser,
          idProfile: dataRegister.idProfile,
        },
        null,
        API_CONSTANTS.PROFILE.CREATE_PROFILE,
        "POST",
        false
      );

      await fetch(
        `${ENVIROMENT}${API_CONSTANTS.USER.USER_PROGRESS}${dataRegister.idSystemUser}?screen=2`
      );
      setIsLoading(false);
      navigate("/documentos-auto");
    } catch (error) {
      setIsLoading(false);
      window.alert(error);
    }
  };

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="light" />
      <Main>
        <div className="backround-img-top">
          <img src={BackDark} alt="" />
        </div>
        <div className="backround-img-btm">
          <img src={BackDarkBtn} alt="" />
        </div>
        <div className="main-info">
          <h3>Información Personal</h3>
          <p>Algunos campos son requeridos</p>
        </div>
        <div>
          <Box component="form" noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                marginTop: "2em",
              }}
            >
              <TextField
                required
                id="name"
                name="name"
                label="Nombre Completo o Razón Social"
                defaultValue=""
                onChange={handlerOnChange}
              />
              <TextField
                type="date"
                required
                id="dateOfBirth"
                name="dateOfBirth"
                label="Nacimiento"
                onChange={handlerOnChange}
              />
              <TextField
                value={dataForm.curp}
                id="curp"
                name="curp"
                label="CURP"
                defaultValue=""
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setDataForm({ ...dataForm, curp: value });
                }}
              />
              <TextField
                value={dataForm.rfc}
                required
                id="rfc"
                name="rfc"
                label="RFC"
                defaultValue=""
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setDataForm({ ...dataForm, rfc: value });
                }}
              />
              <p>Dirección particular o de la Empresa</p>
              <TextField
                required
                id="street"
                name="street"
                label="Calle y Número"
                defaultValue=""
                onChange={handlerOnChange}
              />
              <TextField
                required
                id="neighborhood"
                name="neighborhood"
                label="Colonia"
                defaultValue=""
                onChange={handlerOnChange}
              />
              <TextField
                required
                id="city"
                name="city"
                label="Municipio o  Delegación"
                defaultValue=""
                onChange={handlerOnChange}
              />
              <TextField
                required
                id="state"
                name="state"
                label="Estado"
                defaultValue=""
                onChange={handlerOnChange}
              />
              <TextField
                required
                id="zipCode"
                name="zipCode"
                label="Codigo Postal"
                defaultValue=""
                onChange={handlerOnChange}
              />
            </div>
          </Box>
          <ButtonsGroup>
            <div className="buttons-actions">
              <ButtonNext onClick={handlerSetDataInfoProfile}>
                Siguiente
              </ButtonNext>
            </div>
          </ButtonsGroup>
        </div>
      </Main>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataRegister } = state;
  return {
    dataRegister: dataRegister.dataRegister,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoUserForm);

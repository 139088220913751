const GLOBAL_CONSTANTS = {
  secretKey: "",
  tokenSecretKey: "",
  ROOT_NAME: "system",
  VERSION: "v0.0.1",
  SCREEN_SELFIE: "3af85974-651a-448c-93d8-3ead818f2c1e",
  SET_DATA_USER_PROFILE: "SET_DATA_USER_PROFILE",
  SET_DATA_USER_REGISTER: "SET_DATA_USER_REGISTER",
  SET_DATA_USER_PROCESS: "SET_DATA_USER_PROCESS",
  SET_DATA_USER_LOAN: "SET_DATA_USER_LOAN",
  STATUS_API: {
    WARNING: "WARNING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
  },
};

export default GLOBAL_CONSTANTS;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import isNil from "lodash/isNil";
import moment from "moment";
import { connect } from "react-redux";
import ArrowDown from "../../../assets/img/swip-arrow-down.png";
import NavCenterActionComponent from "../../../components/navCenterAction";
import { callGlobalActionApi } from "../../../utils/actions/actions";
import { API_CONSTANTS } from "../../../utils/constants/apiConstants";
import isEmpty from "lodash/isEmpty";
import { setDataUserLoan } from "../../../utils/dispatchs/userLoanDispatch";

const BodyMain = styled.div``;

const BottomMain = styled.div`
  display: flex;
  align-items: center;

  button {
    border: none;
    width: 100%;
    background: var(--primary-color);
    color: var(--light-color);
    padding: 1em 0px;
    border-radius: 0.8em;
  }
`;

const ContainHistory = styled.div`
  height: 100%;
  border-radius: 0.8em;

  .title-section {
    margin-bottom: 1em;
    h2 {
      margin: 0;
    }
  }

  .column-name {
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;
    padding: 0px 2em;
  }
`;

const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 1em;
  row-gap: 1em;
`;

const List = styled.div`
  padding: 1em 2em;
  background: var(--info-background);
  display: flex;
  flex-direction: column;
  border-radius: 1.2em;

  .info-general-pay {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    cursor: pointer;

    .arrow-collapse {
      position: absolute;
      right: -10px;
      top: -5px;
      button {
        background: transparent;
        outline: none;
        border: none;
      }
    }
  }
`;

const ContainerPageCredits = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 80px;
`;

const CollapseList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  padding: ${(props) => (props.collapse ? "0px" : "1em 0px")};
  max-height: ${(props) => (props.collapse ? "0px" : "500px")};
  overflow-y: hidden;
  transition: all 0.2s ease-in;
  div {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
    span {
      font-size: 0.8em;
      font-weight: 200;
      opacity: 0.8;
    }
    strong {
    }
  }
`;

const SectionContainerList = (props) => {
  const {
    collapse,
    onClick,
    amountMonthly,
    amountInterest,
    amountIva,
    amountMoratorium,
    concept,
    nextPaymentAt,
  } = props;

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  const handleGetDays = (nextPaymentAt) => {
    const today = moment();
    const nextPayment = moment(nextPaymentAt);
    const days = nextPayment.diff(today, "days");
    let daysDiff = "";
    if (days === 0) {
      daysDiff = "Hoy";
    } else if (days === 1) {
      daysDiff = "Mañana";
    } else {
      daysDiff = `En ${days} dias`;
    }
    return daysDiff;
  };

  const formattDate = (date) => {
    let resultDate = "";
    if (isEmpty(date) === false) {
      resultDate = moment(date).format("DD MMM YYYY");
    }
    return resultDate;
  };
  return (
    <List>
      <div
        className="info-general-pay"
        onClick={() => {
          onClick();
        }}
      >
        <div className="arrow-collapse">
          <button
            onClick={() => {
              onClick();
            }}
          >
            <img src={ArrowDown} alt="" />
          </button>
        </div>
        <div>
          <strong>{parseFormatCurrency(amountMonthly, 0, 2)}</strong>
        </div>
        <div>
          <strong>{handleGetDays(nextPaymentAt)}</strong>
        </div>
      </div>
      <CollapseList collapse={collapse}>
        <div className="item-list">
          <span>Concepto</span>
          <span>{concept}</span>
        </div>
        <div>
          <span>Interés</span>
          <span>{parseFormatCurrency(amountInterest, 0, 2)}</span>
        </div>
        <div>
          <span>Interés moratorio diario (25%)</span>
          <span>{parseFormatCurrency(amountMoratorium, 0, 2)}</span>
        </div>
        <div>
          <span>Iva</span>
          <span>{parseFormatCurrency(amountIva, 0, 2)}</span>
        </div>
        <div>
          <strong>Total</strong>
          <strong>{parseFormatCurrency(amountMonthly, 0, 2)}</strong>
        </div>
      </CollapseList>
    </List>
  );
};

const Payments = (props) => {
  const { callGlobalActionApi, dataProcess, dataProfile, setDataUserLoan } =
    props;
  const [dataHistory, setDataHistory] = useState([]);
  const [dynamicStatesCollapse, setDynamicStateCollapse] = useState({});
  const [dynamicStatesCollapse2, setDynamicStateCollapse2] = useState({});
  const [isActiveSomeCollapse, setIsActiveSomeCollapse] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState({});

  const navigate = useNavigate();

  const handleGetLoansToPay = async (id: string) => {
    try {
      const response = await callGlobalActionApi(
        {},
        id,
        API_CONSTANTS.LOAN.GET_NEXT_PAYMENTS,
        "GET",
        false
      );
      const responseResult = response.data;

      setDataHistory(responseResult);
      const objectDynamicState = {};

      responseResult.forEach((element: object) => {
        objectDynamicState[element.idLoan] = true;
      });

      setDynamicStateCollapse(objectDynamicState);
      setDynamicStateCollapse2(objectDynamicState);
    } catch (error) {
      window.alert("Error al obtener los procesos");
    }
  };

  useEffect(() => {
    if (isNil(dataProcess) === false && dataProcess.isApproved === true) {
      handleGetLoansToPay(dataProfile.idSystemUser);
    }
  }, [dataProcess]);

  useEffect(() => {
    setIsActiveSomeCollapse(
      Object.values(dynamicStatesCollapse).some((item) => item === false)
    );
  }, [dynamicStatesCollapse]);

  return (
    <ContainerPageCredits>
      <BodyMain>
        <ContainHistory>
          <div className="title-section">
            <h2>Próximos pagos</h2>
          </div>
          <div className="column-name">
            <span>Monto a pagar</span>
            <span>Vence</span>
          </div>
          <ContainerList>
            {dataProcess?.isApproved === true &&
              dataHistory.map((item, index) => {
                return (
                  <SectionContainerList
                    key={index}
                    collapse={dynamicStatesCollapse[item.idLoan]}
                    {...item}
                    onClick={() => {
                      setSelectedLoan(item);
                      setDynamicStateCollapse({
                        ...dynamicStatesCollapse2,
                        [item.idLoan]: !dynamicStatesCollapse[item.idLoan],
                      });
                    }}
                  />
                );
              })}
            {dataProcess?.isApproved === false && (
              <div>Aún no tienes pagos pendientes</div>
            )}
          </ContainerList>
        </ContainHistory>
      </BodyMain>
      <BottomMain>
        {/* <NavCenterActionComponent
          onClick={(nav) => {
            nav("/selecciona-pago");
          }}
          to="/selecciona-pago"
          label="Continuar"
          type="dark"
        /> */}
        {isActiveSomeCollapse === true && (
          <button
            onClick={() => {
              setDataUserLoan(selectedLoan);
              navigate("/selecciona-pago");
            }}
          >
            Continuar
          </button>
        )}
      </BottomMain>
    </ContainerPageCredits>
  );
};

const mapStateToProps = (state) => {
  const { dataProfile, dataProcess } = state;
  return {
    dataProfile: dataProfile.dataProfile,
    dataProcess: dataProcess.dataProcess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserLoan: (data: object) => dispatch(setDataUserLoan(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);

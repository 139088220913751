import { connect } from "react-redux";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { Navigate } from "react-router-dom";
import { callGlobalActionApi } from "../../utils/actions/actions";

const RequireAuth = (props) => {
  const { dataRegister, dataProfile, children } = props;
  
  if (
    (isNil(dataRegister) === false &&
      isEmpty(dataRegister) === false &&
      isNil(dataRegister.idSystemUser) === false &&
      isEmpty(dataRegister.idSystemUser) === false) ||
    (isNil(dataProfile) === false &&
      isEmpty(dataProfile) === false &&
      isNil(dataProfile.idSystemUser) === false &&
      isEmpty(dataProfile.idSystemUser) === false)
  ) {
    return children;
  }else{
    return <Navigate to="/" />;
  }
};

const mapStateToProps = (state) => {
  const { dataRegister, dataProfile } = state;
  return {
    dataRegister: dataRegister.dataRegister,
    dataProfile: dataProfile.dataProfile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth);

import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { Container } from "../../assets/styles/styledGeneral";
import BackDark from "../../assets/img/swip-back-dark.png";
import OkCheck from "../../assets/img/swip-ok-check.png";
import OkCar from "../../assets/img/swip-ok-car.png";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import NavCenterActionComponent from "../../components/navCenterAction";

const Main = styled.main`
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--size-header) - var(--size-footer));
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 1em;

  .center-img {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .title-page {
    margin: 2em 0px;
    h2 {
      font-weight: bold;
    }
  }
`;

const objectLabels = {
  pending: "En unos momentos recibirás confirmación de tu credito aprobado",
  schedule:
    "Tu cita ha sido agendada correctamente, en unos momentos un agente se comunicará contigo para confirmar tu cita",
  ready: "En unos momentos recibirás tu préstamo",
};

const OkPage = () => {
  const [searchParams] = useSearchParams();
  const typeView = searchParams.get("type");
  const label = objectLabels[typeView];

  return (
    <Container>
      <HeaderComponent type="light" />
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="backround-img"
          style={{
            position: "absolute",
          }}
        >
          <img src={BackDark} alt="" />
        </div>
        <Main>
          <div className="center-img">
            <img src={OkCar} alt="" />
          </div>
          <div className="center-img">
            <img src={OkCheck} alt="" />
          </div>
          <div className="center-img">
            <h2>{label}</h2>
          </div>
        </Main>
      </div>
      <FooterComponent>
        <NavCenterActionComponent
          onClick={(nav) => {
            nav("/home/creditos");
          }}
          label="Continuar"
          type="light"
        />
      </FooterComponent>
    </Container>
  );
};

export default OkPage;

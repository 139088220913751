import { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import isNil from "lodash/isNil";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Roundy from "roundy";
import FormDialogComponent from "../../../components/dialogAmount";
import { setDataUserLoan } from "../../../utils/dispatchs/userLoanDispatch";

const Container = styled.div`
  color: var(--light-color);
  .amount-pay {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    padding: 0px 2em;
    font-size: 1.1em;
    color: var(--secondary-color);
  }
  .title-verify {
    padding: 1em 0px;
  }
`;

const ContainerGauge = styled.div`
  display: flex;
  justify-content: center;
  height: 12em;
`;

const SectionGauge = styled.div`
  position: relative;
  .range-total {
    position: absolute;
    font-size: 0.8em;
    right: -15px;
    bottom: 65px;
  }

  .sliderHandle::after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    right: 0px;
  }
`;

const ChildGauge = styled.div`
  position: absolute;
  top: 30px;
  left: 37px;
  background: transparent;
  width: 190px;
  height: 111px;
  display: flex;
  row-gap: 1em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--secondary-color);

  .container-amount {
    display: flex;
    justify-content: center;
  }

  .description-amount {
    font-weight: 200;
    p {
      font-size: 1.3em;
      margin: 0px;
    }
  }
`;

const StyleInput = styled.div`
  display: flex;
  align-items: baseline;
  h2 {
    margin: 0px;
    font-size: 2em;
  }
  span {
    font-size: 0.7em;
    font-weight: 100;
  }
`;

const ContentInfo = styled.div`
  background: var(--primary-color);
  border-radius: 0.8em;
  padding: 1em;
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  .content-request-tp {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    border-top: 2px solid var(--light-color);
    border-bottom: 1px solid var(--light-color);
    .left-info {
    }
    .right-info {
      span {
        opacity: 0.6;
        font-weight: 100;
      }
    }
    div {
      display: flex;
      row-gap: 10px;
      flex-direction: column;
    }
  }
  .content-request-bm {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    span {
      color: var(--secondary-color);
      font-weight: 500;
    }
  }
`;

const ContainerButton = styled.div`
  margin-top: 1em;
  display: flex;
  align-items: center;

  button {
    border: none;
    width: 100%;
    background: var(--light-color);
    color: var(--primary-color);
    padding: 1em 0px;
    border-radius: 0.8em;
  }
`;

const PayContributeCapital = (props) => {
  const { dataLoan, idPaymentType, setDataUserLoan } = props;
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const navigation = useNavigate();
  const limitRange = dataLoan.amountLoan;

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  const parseValueStyle = (valueAmount) => {
    const valueOnchange = parseFormatCurrency(valueAmount, 0, 2);
    return valueOnchange.replace("$", "");
  };

  return (
    <Container>
      <FormDialogComponent
        open={open}
        onClose={(val) => {
          setOpen(false);
          setValue(val);
        }}
        onCancel={() => {
          setOpen(false);
        }}
        limit={limitRange}
      />
      <div className="title-verify">
        <h3>Aporte a capital</h3>
      </div>
      <ContainerGauge>
        <SectionGauge>
          <ChildGauge>
            <div className="description-amount">
              <p>Monto</p>
            </div>
            <div
              className="container-amount"
              onClick={() => {
                setOpen(true);
              }}
            >
              <StyleInput>
                <h2>$</h2>
                <h2>{parseValueStyle(value)}</h2>
                {/* <CurrencyInputComponent
                      value={value}
                      onChange={(value) => {
                        setValue(value);
                      }}
                    /> */}
                <span>MXN</span>
              </StyleInput>
            </div>
          </ChildGauge>
          <Roundy
            strokeWidth={15}
            thumbSize={3}
            value={value}
            arcSize={180}
            min={0}
            max={limitRange}
            stepSize={1000}
            radius={130}
            color="var(--secondary-color)"
            bgColor="var(--shadow-dark)"
            onChange={(value) => setValue(value)}
            onAfterChange={() => {}}
            overrideStyle={{}}
            rotationOffset={0}
            sliced={false}
          />
        </SectionGauge>
      </ContainerGauge>
      <div className="amount-pay">
        <span>{parseFormatCurrency(value, 0, 2)}</span>
        <span>{dataLoan.concept}</span>
      </div>
      <ContentInfo>
        <div className="content-request-tp">
          <div className="left-info">
            <span>Monto seleccionado</span>
            <span>Interés mensual</span>
            <span>Iva (16%)</span>
            <span>Total a capital</span>
            <span>Próxima fecha a pagar</span>
          </div>
          <div className="right-info">
            <span>{parseFormatCurrency(value, 0, 2)}</span>
            <span>-{parseFormatCurrency(dataLoan.amountInterest, 0, 2)}</span>
            <span>-{parseFormatCurrency(dataLoan.amountIva, 0, 2)}</span>
            <span>
              {parseFormatCurrency(value - dataLoan.amountMonthly, 0, 2)}
            </span>
            <span>
              {moment(dataLoan.nextPaymentAt)
                .add(1, "M")
                .format("DD MMMM YYYY")}
            </span>
          </div>
        </div>
        <div className="content-request-bm">
          <div>Total</div>
          <span>{parseFormatCurrency(value, 0, 2)}</span>
        </div>
      </ContentInfo>
      <ContainerButton>
        <button
          onClick={() => {
            setDataUserLoan({
              ...dataLoan,
              amountToPay: value,
            });
            navigation(`/informacion-pago?payment=${idPaymentType}`);
          }}
        >
          Siguiente
        </button>
      </ContainerButton>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataLoan } = state;
  return {
    dataLoan: dataLoan.dataLoan,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDataUserLoan: (data) => dispatch(setDataUserLoan(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayContributeCapital);

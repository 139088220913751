import { BrowserRouter, Route } from "react-router-dom";
import SlideRoutes from "react-slide-routes";
import Home from "./views/home/home";
import LoanRequest from "./views/loan/loanRequest";
import LoanConfirm from "./views/loan/loanConfirm";
import OkPage from "./views/okPage/okPage";
import SelectPayments from "./views/payment/selectPayment";
import PaymentInformation from "./views/payment/paymentInformation";
import InfoAutoForm from "./views/infoAuto/infoAutoForm";
import InfoUserForm from "./views/infoUser/infoUserForm";
import InfoUserBankForm from "./views/infoUser/infoUserBankForm";
import RegisterUserForm from "./views/login/registerUser";
import LoginUser from "./views/login/loginUser";
import PrincipalPage from "./views/login/principalPage";
import RequireAuth from "./views/container/requireAuth";
import LogOut from "./views/container/logOut";
import Documents from "./views/documents/documents";
import AddNewCar from "./views/infoAuto/addNewCar";
import GPSCost from "./views/gps/gpsCost";
import ScheduleAppointment from "./views/schedule/scheduleAppointment";
import Payment from "./views/payment/payment";
import RecoveryPassword from "./views/login/recoveryPassword";
import ChangePassword from "./views/login/changePassword";
import OnlyMobileVersion from "./views/container/onlyMobileVersion";

function App() {
  return (
    <BrowserRouter>
      <SlideRoutes>
        <Route path="/logout" element={<LogOut />} />
        <Route path="/" element={<PrincipalPage />} />
        <Route
          path="/login"
          element={
            <OnlyMobileVersion>
              <LoginUser />
            </OnlyMobileVersion>
          }
        />
        <Route
          path="/registro"
          element={
            <OnlyMobileVersion>
              <RegisterUserForm />
            </OnlyMobileVersion>
          }
        />
        <Route
          path="/recuperar-contrasena"
          element={
            <OnlyMobileVersion>
              <RecoveryPassword />
            </OnlyMobileVersion>
          }
        />
        <Route
          path="/cambio-contrasena"
          element={
            <OnlyMobileVersion>
              <ChangePassword />
            </OnlyMobileVersion>
          }
        />
        <Route path="/programar-cita" element={<ScheduleAppointment />} />
        <Route
          path="/solicitud-prestamo"
          element={
            <RequireAuth>
              <LoanRequest />
            </RequireAuth>
          }
        />
        <Route
          path="/solicitud-prestamo/confirma"
          element={
            <RequireAuth>
              <LoanConfirm />
            </RequireAuth>
          }
        />
        <Route
          path="/documentos/*"
          element={
            <RequireAuth>
              <Documents
                path="/documentos/"
                flow_document_type={0}
                to="/informacion-personal"
              />
            </RequireAuth>
          }
        />
        <Route
          path="/informacion-personal"
          element={
            <RequireAuth>
              <InfoUserForm />
            </RequireAuth>
          }
        />
        <Route
          path="/documentos-auto/*"
          element={
            <RequireAuth>
              <Documents
                path="/documentos-auto/"
                flow_document_type={1} //es 1
                to="/informacion-auto"
              />
            </RequireAuth>
          }
        />
        <Route
          path="/informacion-auto"
          element={
            <RequireAuth>
              <InfoAutoForm />
            </RequireAuth>
          }
        />
        <Route
          path="/finalizar-proceso"
          element={
            <RequireAuth>
              <AddNewCar />
            </RequireAuth>
          }
        />
        <Route
          path="/informacion-bancaria"
          element={
            <RequireAuth>
              <InfoUserBankForm />
            </RequireAuth>
          }
        />
        <Route
          path="/costo-gps"
          element={
            <RequireAuth>
              <GPSCost />
            </RequireAuth>
          }
        />
        <Route
          path="/confirmado"
          element={
            <RequireAuth>
              <OkPage />
            </RequireAuth>
          }
        />
        <Route
          path="/home/*"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="/selecciona-pago/*"
          element={
            <RequireAuth>
              <SelectPayments />
            </RequireAuth>
          }
        />
        <Route
          path="/informacion-pago"
          element={
            <RequireAuth>
              <PaymentInformation />
            </RequireAuth>
          }
        />
        <Route
          path="/pago"
          element={
            <RequireAuth>
              <Payment />
            </RequireAuth>
          }
        />
      </SlideRoutes>
    </BrowserRouter>
  );
}

export default App;

const fileReaderPromise = async (fileIndex) => {
  if (fileIndex.type.startsWith("image/")) {
    if (fileIndex.size >= 5242880) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileIndex);
        reader.onload = async (event) => {
          const eventTarget = event.target;
          const imgElement = document.createElement("img");
          imgElement.src = eventTarget.result;
          imgElement.onload = async (event1) => {
            const eventTarget1 = event1.target;
            const canvas = document.createElement("canvas");
            const width = eventTarget1.width;
            const height = eventTarget1.height;

            const MAX_WIDTH = 1200;
            const scaleSize = MAX_WIDTH / width;

            canvas.width = MAX_WIDTH;
            canvas.height = height * scaleSize;

            const ctx = canvas.getContext("2d");
            if (ctx) {
              ctx.drawImage(eventTarget1, 0, 0, canvas.width, canvas.height);
              const srcEncoded = ctx.canvas.toDataURL("image/jpeg", 0.8);
              const imageSizeInBytes = srcEncoded.length;
              resolve({
                src: srcEncoded,
                metadata: {
                  type: "image/jpeg",
                  name: fileIndex.name,
                  size: imageSizeInBytes,
                },
              });
            } else {
              if (eventTarget.result) {
                resolve({
                  src: eventTarget.result,
                  metadata: {
                    type: fileIndex.type,
                    name: fileIndex.name,
                    size: fileIndex.size,
                  },
                });
              } else {
                reject(new Error("Error al leer el archivo"));
              }
            }
          };
        };
      });
    } else {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileIndex);
        reader.onload = async (event) => {
          const eventTarget = event.target;
          if (eventTarget.result) {
            resolve({
              src: eventTarget.result,
              metadata: {
                type: fileIndex.type,
                name: fileIndex.name,
                size: fileIndex.size,
              },
            });
          } else {
            reject(new Error("Error al leer el archivo"));
          }
        };
      });
    }
  } else {
    if (fileIndex.size <= 10485760) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileIndex);
        reader.onload = async (event) => {
          const eventTarget = event.target;
          if (eventTarget.result) {
            resolve({
              src: eventTarget.result,
              metadata: {
                type: fileIndex.type,
                name: fileIndex.name,
                size: fileIndex.size,
              },
            });
          } else {
            reject(new Error("Error al leer el archivo"));
          }
        };
      });
    }
    throw new Error("El archivo es demasiado grande");
  }
};

export default fileReaderPromise;

import { useContext, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { callSetCustomerInDocument } from "../../../utils/actions/actions";
import ENVIROMENT from "../../../utils/constants/enviroments";
import { API_CONSTANTS } from "../../../utils/constants/apiConstants";
import ContextDocuments from "../../../contexts/contextDocuments";
import Loader from "../../../components/loader";
import fileReaderPromise from "../../../utils/actions/resizeImage";

const SectionInputUpload = styled.div`
  display: flex;
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .border-upload {
    overflow: hidden;
    position: relative;
    border: 3px solid var(--secondary-color);
    border-radius: 1em;
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .image-container {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .delete-doc-process {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
    .contain-image {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .title-file-type {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 2.5em;
        color: var(--primary-color);
        text-align: center;
        font-weight: 800;
        text-transform: uppercase;
      }
    }
    .upload-file {
      cursor: pointer;
      color: var(--light-color);
      background: var(--secondary-color);
      border-radius: 0.8em;
      padding: 0.5em 1em;
      span {
        font-size: 1em;
        text-align: center;
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 820px) {
    flex-direction: column;
    row-gap: 1em;
  }
`;

const ButtonsGroup = styled.div`
  .buttons-actions {
    margin-top: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  width: 70%;
  font-size: 1em;
`;

const ViewDocumentGeneric = (props) => {
  const {
    name,
    description,
    type,
    capture,
    accept,
    label,
    bucketSource,
    idDocumentType,
    idScreen,
    callSetCustomerInDocument,
    dataRegister,
    isLast,
    nextScreen,
  } = props;

  const [picture, setPicture] = useState("");
  const [metaImage, setMetaImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const contextDocuments = useContext(ContextDocuments);
  const { idFlowDocumentType } = contextDocuments;

  const navigate = useNavigate();

  const handlerUploadFile = async (e) => {
    try {
      const files = e.target.files[0];
      if (!files) return;

      const resizeImage = await fileReaderPromise(files);

      setMetaImage(resizeImage.metadata);
      setPicture(resizeImage.src);

      document.getElementById("id-file-upload-checklist").value = "";
    } catch (error) {
      document.getElementById("id-file-upload-checklist").value = "";
      window.alert(error);
    }
  };

  const handlerGenerateBlobImage = async (src, data) => {
    try {
      setIsLoading(true);
      const urlObject = await fetch(src);
      const blobFile = await urlObject.blob();
      await callSetCustomerInDocument(
        blobFile,
        { ...data, ...metaImage },
        () => {},
        "POST",
        false
      );
      if (isLast) {
        await fetch(
          `${ENVIROMENT}${API_CONSTANTS.USER.USER_PROGRESS}${
            dataRegister.idSystemUser
          }?screen=${idFlowDocumentType === 0 ? "1" : "3"}`
        );
      }
      setIsLoading(false);
      navigate(nextScreen);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleGetImage = (src, data) => {
    if (data.type === "application/pdf") {
      return "https://swiputils.s3.amazonaws.com/pdf.png";
    } else if (
      data.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      data.type === "application/msword" ||
      data.type === "application/docx" ||
      data.type === "application/doc"
    ) {
      return "https://swiputils.s3.amazonaws.com/docx.png";
    } else if (data.type.includes("image")) {
      return src;
    } else {
      return src;
    }
  };

  return (
    <div className="main-info">
      <Loader isVisible={isLoading} />
      <h3>{name}</h3>
      <p>{description}</p>
      <SectionInputUpload>
        <div className="border-upload">
          {picture === "" && (
            <>
              <label
                className="upload-file"
                htmlFor={`id-file-upload-checklist`}
              >
                <span>{label}</span>
              </label>
              <input
                id={`id-file-upload-checklist`}
                accept={accept}
                style={{ display: "none" }}
                type={type}
                capture={capture}
                onChange={handlerUploadFile}
              />
            </>
          )}
          {picture !== "" && (
            <img
              src={handleGetImage(picture, metaImage)}
              alt="imagen"
              className="image-container"
            />
          )}
        </div>
      </SectionInputUpload>
      <ButtonsGroup>
        <div className="buttons-actions">
          {picture !== "" && (
            <ButtonNext
              onClick={() => {
                handlerGenerateBlobImage(picture, {
                  bucketSource,
                  idDocumentType,
                  idScreen,
                  idSystemUser: dataRegister.idSystemUser,
                  idPersonalDocuments: dataRegister.idPersonalDocuments,
                  idUserType: dataRegister.idUserType,
                  idFlowDocumentType,
                  idCarDocuments: dataRegister.idCarDocuments,
                });
              }}
            >
              Siguiente
            </ButtonNext>
          )}
        </div>
      </ButtonsGroup>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { dataRegister } = state;
  return {
    dataRegister: dataRegister.dataRegister,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callSetCustomerInDocument: (file, data, callback, method, token) =>
    dispatch(callSetCustomerInDocument(file, data, callback, method, token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDocumentGeneric);

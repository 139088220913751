import { useEffect, useState } from "react";
import isNil from "lodash/isNil";
import styled from "styled-components";
import { connect } from "react-redux";
import NavCenterActionComponent from "../../../components/navCenterAction";
import { callGlobalActionApi } from "../../../utils/actions/actions";
import { API_CONSTANTS } from "../../../utils/constants/apiConstants";
import ComponentListHistory from "../components/componentListHistory";

const BodyMain = styled.div``;

const BottomMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainHistory = styled.div`
  background: var(--info-background);
  height: 100%;
  border-radius: 0.8em;

  .title-section {
    padding: 1em 2em;
    h2 {
      margin: 0;
    }
  }
`;

const ContainerPageCredits = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 80px;
`;

const Credits = (props) => {
  const { callGlobalActionApi, dataProcess, dataProfile } = props;
  const [dataHistory, setDataHistory] = useState([]);

  const handleGetLoans = async (id) => {
    try {
      const response = await callGlobalActionApi(
        {},
        id,
        API_CONSTANTS.LOAN.GET_ALL_LOANS,
        "GET",
        false
      );

      setDataHistory(response.data);
    } catch (error) {
      window.alert("Error al obtener los procesos");
    }
  };

  useEffect(() => {
    
    if (isNil(dataProcess) === false && dataProcess.isApproved === true) {
      handleGetLoans(dataProfile.idSystemUser);
    }
  }, [dataProcess]);

  return isNil(dataProcess) === false ? (
    <ContainerPageCredits>
      <BodyMain>
        <ContainHistory>
          {dataProcess.isApproved === true && (
            <div className="title-section">
              <h2>Historial</h2>
            </div>
          )}
          <ComponentListHistory
            dataProcess={dataProcess}
            isApproved={
              isNil(dataProcess) === false ? dataProcess.isApproved : false
            }
            dataHistory={dataHistory}
          />
        </ContainHistory>
      </BodyMain>
      <BottomMain>
        {(isNil(dataProcess) === true || dataProcess.amountAvailable !== 0) &&
          dataProcess.isApproved === true && (
            <NavCenterActionComponent
              to="/solicitud-prestamo"
              onClick={(nav) => {
                nav("/solicitud-prestamo");
              }}
              type="primary"
              label="Usa tu dinero"
            />
          )}
        {isNil(dataProcess) === false &&
          dataProcess.amountAvailable === 0 &&
          dataProcess.isApproved === true && (
            <NavCenterActionComponent
              onClick={(nav) => {
                // nav("/solicitud-prestamo");
              }}
              label="Aumentar crédito"
            />
          )}
      </BottomMain>
    </ContainerPageCredits>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => {
  const { dataProfile, dataProcess } = state;
  return {
    dataProfile: dataProfile.dataProfile,
    dataProcess: dataProcess.dataProcess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Credits);

import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import moment from "moment";
import isNil from "lodash/isNil";
import { connect } from "react-redux";
import HeaderComponent from "../../components/header";
import BackDark from "../../assets/img/swip-back-dark.png";
import BackDarkBtn from "../../assets/img/swip-back-dark-btm.png";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import { setDataUserLoan } from "../../utils/dispatchs/userLoanDispatch";
import { callGlobalActionApi } from "../../utils/actions/actions";
import Loader from "../../components/loader";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--light-color);
  background: var(--primary-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }
  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }
  .main-info {
    position: relative;
  }

  .footer-button {
    margin-top: 2em;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1em;

    button {
      font-weight: bold;
      cursor: pointer;
      border: none;
      background: var(--light-color);
      color: var(--primary-color);
      border-radius: 0.8em;
      padding: 0.8em 0px;
    }
  }
`;

const StepsInfo = styled.div`
  background: var(--info-light-background);
  padding: 1em;
  border-radius: 0.5em;
  margin-top: 5em;
`;

const Steps = styled.div`
  font-size: 0.8em;
  font-weight: 600;
  min-height: 4em;
  display: flex;
  column-gap: 1em;
  color: var(--primary-color);

  .indicator-step {
    position: relative;
    padding: 2px 0px 0px 0px;
    display: flex;
    justify-content: center;
    .circle-step {
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: var(--secondary-color);
    }
    .line-vertical {
      position: absolute;
      left: 50%;
      top: 1em;
      width: 2px;
      height: 100%;
      background: var(--secondary-color);
    }
  }
`;

const SectionSendMail = styled.div`
  padding: 1em;
  margin-top: 1.5em;
  background: var(--info-light-background);
  color: var(--primary-color);
  border-radius: 0.5em;
  .title-section {
    font-size: 0.7em;
    font-weight: 600;
  }
`;

const InputEmail = styled.div`
  margin-top: 1em;
  border-radius: 0.5em;
  border: 1px solid var(--primary-color);
  display: grid;
  grid-template-columns: 30px 3fr 1fr;
  position: relative;
  overflow: hidden;

  .icon-email {
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-email {
    input {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      outline: none;
    }
    input:focus {
      border: none;
    }
  }
  .sent-email {
    overflow: hidden;
    button {
      cursor: pointer;
      border: none;
      width: 100%;
      height: 100%;
      font-weight: 700;
      background: transparent;
      color: var(--primary-color);
    }
  }
`;

const PaymentInformation = (props) => {
  const {
    dataLoan,
    callGlobalActionApi,
    dataProfile,
    dataProcess,
    setDataUserLoan,
  } = props;
  const [arrayText, setArrayText] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const idPaymentType = searchParams.get("payment");

  const navigate = useNavigate();

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  const handleGetUrlToPayment = async (data) => {
    try {
      setIsLoading(true);
      const response = await callGlobalActionApi(
        data,
        null,
        API_CONSTANTS.PAYMENT.CHECKOUT_PAYMENT,
        "POST",
        true
      );
      const dataUrl = response.url;
      setDataUserLoan({});
      setIsLoading(false);
      window.open(dataUrl, "_self");
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    const array = [
      "Ingresa a la aplicación de tu banco",
      "Elige realizar transferencia",
      "Ingresa la siguiente clabe 2249 3667 2788 8394 21",
      `Ingresa en concepto “Pago swip ${moment(dataLoan.nextPaymentAt).format(
        "MMMM YYYY"
      )}”`,
      `Ingresa el Monto por la cantidad de ${parseFormatCurrency(
        dataLoan.amountToPay,
        0,
        2
      )}`,
    ];
    setArrayText(array);
  }, []);

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="dark" />
      <Main>
        <div className="backround-img-top">
          <img src={BackDark} alt="" />
        </div>
        <div className="backround-img-btm">
          <img src={BackDarkBtn} alt="" />
        </div>
        <div className="main-info">
          <h3>Para realizar tu pago sigue las siguientes instrucciones</h3>
          <StepsInfo>
            {arrayText.map((item, index) => (
              <Steps key={index}>
                <div className="indicator-step">
                  <div className="circle-step"></div>
                  {arrayText.length - 1 === index ? null : (
                    <div className="line-vertical"></div>
                  )}
                </div>
                <div>{item}</div>
              </Steps>
            ))}
          </StepsInfo>
          {/* <SectionSendMail>
            <span className="title-section">Enviar información de pago a:</span>
            <div>
              <InputEmail>
                <div className="icon-email">
                  <img src={EmailIcon} alt="email-icon" />
                </div>
                <div className="input-email">
                  <input type="email" name="" id="" />
                </div>
                <div className="sent-email">
                  <button>Enviar</button>
                </div>
              </InputEmail>
            </div>
          </SectionSendMail> */}
          <h3>Tambíen puedes pagar en linea</h3>
        </div>
        <div className="footer-button">
          <button
            onClick={async () => {
              try {
                handleGetUrlToPayment({
                  amount: dataLoan.amountToPay,
                  idSystemUser: dataProcess.idSystemUser,
                  idProcesses: dataProcess.idProcesses,
                  idLoans: dataProcess.idLoans,
                  idLoan: dataLoan.idLoan,
                  email: dataProfile.email,
                  idPaymentType,
                });
              } catch (error) {}
            }}
          >
            Pagar en Linea
          </button>
          <button
            onClick={() => {
              setDataUserLoan({});
              navigate("/home");
            }}
          >
            Finalizar
          </button>
        </div>
      </Main>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataLoan, dataProfile, dataProcess } = state;
  return {
    dataProfile: dataProfile.dataProfile,
    dataProcess: dataProcess.dataProcess,
    dataLoan: dataLoan.dataLoan,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserLoan: (data) => dispatch(setDataUserLoan(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInformation);

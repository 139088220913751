import styled from "styled-components";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import BackDark from "../../assets/img/swip-back-dark.png";
import BackDarkBtn from "../../assets/img/swip-back-dark-btm.png";
import HeaderComponent from "../../components/header";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import { useState } from "react";
import { useOnChangeInput } from "../../hooks";
import GLOBAL_CONSTANTS from "../../utils/constants/globalConstants";
import { setDataUserRegister } from "../../utils/dispatchs/userRegisterDispatch";
import ENVIROMENT from "../../utils/constants/enviroments";
import Loader from "../../components/loader";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  overflow-y: scroll;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  margin-top: 2em;
  .terms-conditions {
    font-size: 0.7em;
    color: var(-primary-color);
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .buttons-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  width: 70%;
  font-size: 1em;
`;

const RegisterUserForm = (props) => {
  const { callGlobalActionApi, setDataUserRegister } = props;
  const navigate = useNavigate();
  const initialState = {
    username: "",
    email: "",
    phoneNumber: "",
    password: "",
    idUserType: 0,
    screenNumber: 0,
  };
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dataForm, handlerOnChange] = useOnChangeInput(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const handlerSignInUser = async (password) => {
    try {
      setIsLoading(true);
      if (dataForm.password === password) {
        const response = await callGlobalActionApi(
          dataForm,
          null,
          API_CONSTANTS.USER.USER_REGISTER,
          "POST",
          false
        );

        await fetch(
          `${ENVIROMENT}${API_CONSTANTS.USER.USER_PROGRESS}${response.idSystemUser}?screen=0`
        );

        setDataUserRegister(response);
        setIsLoading(false);
        window.Intercom("boot", {
          app_id: "otekw0v8",
          email: dataForm.email,
          name: dataForm.username,
          user_id: response.idSystemUser,
        });
        navigate(`/documentos/${GLOBAL_CONSTANTS.SCREEN_SELFIE}`);
      } else {
        setIsLoading(false);
        throw new Error("Las contraseñas no coinciden");
      }
    } catch (error) {
      setIsLoading(false);
      window.alert(error);
    }
  };

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="light" isVisibleMenu={false} />
      <Main>
        <div className="backround-img-top">
          <img src={BackDark} alt="" />
        </div>
        <div className="backround-img-btm">
          <img src={BackDarkBtn} alt="" />
        </div>
        <div className="main-info">
          <h3>Crear tu cuenta</h3>
          <p>Tu linea de credito en minutos</p>
        </div>
        <div>
          <Box component="form" noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5em",
                marginTop: "2em",
              }}
            >
              <TextField
                required
                id="username"
                type="text"
                name="username"
                label="Nombre de usuario ej: John, Mike, etc."
                defaultValue=""
                onChange={handlerOnChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                required
                id="cellphone"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                type="number"
                name="phoneNumber"
                label="Ingresa tu Whatsapp o numero de celular"
                defaultValue=""
                onChange={handlerOnChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WhatsAppIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                required
                id="username"
                name="email"
                label="Ingresa tu correo electronico"
                defaultValue=""
                onChange={handlerOnChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                required
                id="password-input"
                name="password"
                label="Ingresa una contraseña"
                type="password"
                defaultValue=""
                onChange={handlerOnChange}
              />
              <TextField
                required
                id="password-input-confirm"
                name="password"
                label="Confirma tu contraseña"
                type="password"
                defaultValue=""
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              <p
                style={{
                  margin: "0",
                  fontSize: "0.8em",
                }}
              >
                ¿Que tipo de persona eres?
              </p>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="physical"
                name="idUserType"
                style={{
                  fontSize: "0.7em",
                }}
                onChange={handlerOnChange}
              >
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Fisica y/o con actividad empresarial"
                />
                <FormControlLabel value={1} control={<Radio />} label="Moral" />
              </RadioGroup>
            </div>
          </Box>
          <ButtonsGroup>
            <div className="terms-conditions">
              <span>
                Al hacer clic en continuar aceptas nuestros terminos y
                condiciones asi como nuestro aviso de privacidad
              </span>
            </div>
            <div className="buttons-actions">
              <ButtonNext
                onClick={() => {
                  handlerSignInUser(confirmPassword);
                }}
              >
                Continuar
              </ButtonNext>
            </div>
          </ButtonsGroup>
        </div>
      </Main>
    </Container>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserRegister: (data) => dispatch(setDataUserRegister(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUserForm);

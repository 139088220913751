import { useEffect } from "react";
import Roundy from "roundy";
import { connect } from "react-redux";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import styled from "styled-components";
import { Container } from "../../assets/styles/styledGeneral";
import BackDark from "../../assets/img/swip-back-dark.png";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import NavCenterActionComponent from "../../components/navCenterAction";
import FormDialogComponent from "../../components/dialogAmount";
import { setDataUserProcess } from "../../utils/dispatchs/userProcessDispatch";
import { NavigateFunction } from "react-router-dom";

const SectionGauge = styled.div`
  position: relative;
  .range-total {
    position: absolute;
    font-size: 0.8em;
    right: -15px;
    bottom: 65px;
  }

  .sliderHandle::after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    right: 0px;
  }
`;

const Main = styled.main`
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--size-header) - var(--size-footer));
  display: grid;
  grid-template-rows: 2fr 3fr;
  padding: 0px 1em;

  .title-page {
    margin: 2em 0px;
    h2 {
      font-weight: bold;
    }
  }
`;

const ContainerGauge = styled.div`
  display: flex;
  justify-content: center;
`;

const TopMain = styled.div`
  position: relative;
  .backround-img {
    position: relative;
    img {
      position: absolute;
      width: 100%;
    }
  }
`;

const BottomMain = styled.div`
  .content-request-calc {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }
`;

const ChildGauge = styled.div`
  position: absolute;
  top: 30px;
  left: 37px;
  background: transparent;
  width: 190px;
  height: 111px;
  display: flex;
  row-gap: 1em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--secondary-color);

  .container-amount {
    display: flex;
    justify-content: center;
  }

  .description-amount {
    font-weight: 200;
    p {
      font-size: 1.3em;
      margin: 0px;
    }
  }
`;

const StyleInput = styled.div`
  display: flex;
  align-items: baseline;
  h2 {
    margin: 0px;
    font-size: 2em;
  }
  span {
    font-size: 0.7em;
    font-weight: 100;
  }
`;

const ContentInfo = styled.div`
  background: var(--primary-color);
  border-radius: 0.8em;
  padding: 1em;
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  .content-request-tp {
    display: flex;
    justify-content: space-between;
    padding: 0px 1em 1.2em 1em;
    border-bottom: 2px solid var(--light-color);
    .left-info {
    }
    .right-info {
      span {
        opacity: 0.6;
        font-weight: 100;
      }
    }
    div {
      display: flex;
      row-gap: 10px;
      flex-direction: column;
    }
  }
  .content-request-bm {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    span {
      color: var(--secondary-color);
      font-weight: 500;
    }
  }
`;

const ContentInfoTotal = styled.div`
  background: var(--primary-color);
  border-radius: 0.8em;
  padding: 2em;
  color: var(--light-color);
  display: flex;
  justify-content: space-between;

  .left-info {
    h3 {
      margin: 0px;
      color: var(--secondary-color);
    }
    span {
      font-size: 0.8em;
      font-weight: 200;
      opacity: 0.6;
    }
  }
  .right-info {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    font-size: 1.1em;
  }
`;

const LoanRequest = (props) => {
  const { setDataUserProcess, dataProcess } = props;

  const initState = {
    amountLoan: 0,
    amountMonthly: 0,
    amountIva: 0,
    amountInterest: 0,
    amountPayOff: 0,
  };

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [metaDataLoan, setMetadataLoan] = useState(initState);

  const limitRange = dataProcess.amountAvailable;

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  useEffect(() => {
    const calcInterest = value * dataProcess.interestRate;
    const calcIva = calcInterest * dataProcess.tax;
    const calcMonthly = calcInterest + calcIva;
    const calcTotal = value + calcMonthly;

    setMetadataLoan({
      amountMonthly: calcMonthly,
      amountLoan: value,
      amountIva: calcIva,
      amountInterest: calcInterest,
      amountPayOff: calcTotal,
    });
  }, [value]);

  useEffect(() => {
    if (
      isEmpty(dataProcess) === false &&
      isNil(dataProcess.metaDataLoan) === false
    ) {
      setValue(dataProcess.metaDataLoan.amountLoan);
      setMetadataLoan(dataProcess.metaDataLoan);
    }
  }, [dataProcess]);

  const parseValueStyle = (valueAmount) => {
    const valueOnchange = parseFormatCurrency(valueAmount, 0, 2);
    return valueOnchange.replace("$", "");
  };

  return (
    <Container>
      <HeaderComponent type="light" />
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="backround-img"
          style={{
            position: "absolute",
          }}
        >
          <img src={BackDark} alt="" />
        </div>
        <Main>
          <FormDialogComponent
            open={open}
            onClose={(val) => {
              setOpen(false);
              setValue(val);
            }}
            onCancel={() => {
              setOpen(false);
            }}
            limit={limitRange}
          />
          <TopMain>
            <div className="title-page">
              <h2>Elige el monto de tu préstamo</h2>
            </div>
            <ContainerGauge>
              <SectionGauge>
                <ChildGauge>
                  <div className="description-amount">
                    <p>Monto</p>
                  </div>
                  <div
                    className="container-amount"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <StyleInput>
                      <h2>$</h2>
                      <h2>{parseValueStyle(value)}</h2>
                      {/* <CurrencyInputComponent
                      value={value}
                      onChange={(value) => {
                        setValue(value);
                      }}
                    /> */}
                      <span>MXN</span>
                    </StyleInput>
                  </div>
                </ChildGauge>
                <Roundy
                  strokeWidth={15}
                  thumbSize={3}
                  value={value}
                  arcSize={220}
                  min={0}
                  max={dataProcess.amountAvailable}
                  stepSize={5000}
                  radius={130}
                  color="var(--secondary-color)"
                  bgColor="var(--primary-color)"
                  onChange={(value) => setValue(value)}
                  onAfterChange={() => {}}
                  overrideStyle={{}}
                  rotationOffset={-20}
                  sliced={false}
                />
                <div className="range-total">
                  Disponible{" "}
                  {parseFormatCurrency(dataProcess.amountAvailable, 0, 2)}
                </div>
              </SectionGauge>
            </ContainerGauge>
          </TopMain>
          <BottomMain>
            <h3>Tu pago mensual total:</h3>
            <div className="content-request-calc">
              <ContentInfo>
                <div className="content-request-tp">
                  <div className="left-info">
                    <span>{parseFormatCurrency(value, 0, 2)}</span>
                    <span>
                      {parseFormatCurrency(metaDataLoan.amountInterest, 2, 2)}
                    </span>
                    <span>
                      {parseFormatCurrency(metaDataLoan.amountIva, 2, 2)}
                    </span>
                  </div>
                  <div className="right-info">
                    <span>Préstamo</span>
                    <span>Interés {dataProcess.interestRate * 100}%</span>
                    <span>Iva {dataProcess.tax * 100}%</span>
                  </div>
                </div>
                <div className="content-request-bm">
                  <div>Tu pago mensual total:</div>
                  <span>
                    {" "}
                    {parseFormatCurrency(metaDataLoan.amountMonthly, 2, 2)}
                  </span>
                </div>
              </ContentInfo>
              <ContentInfoTotal>
                <div className="left-info">
                  <h3>Total para liquidar</h3>
                  <span>Total para liquidar el mes siguiente</span>
                </div>
                <div className="right-info">
                  <span>
                    {" "}
                    {parseFormatCurrency(metaDataLoan.amountPayOff, 2, 2)}
                  </span>
                </div>
              </ContentInfoTotal>
            </div>
          </BottomMain>
        </Main>
      </div>
      <FooterComponent>
        <NavCenterActionComponent
          to="/solicitud-prestamo/confirma"
          onClick={(navTo) => {
            setDataUserProcess({ ...dataProcess, metaDataLoan });
            navTo("/solicitud-prestamo/confirma");
          }}
          label="Siguiente"
          type="light"
        />
      </FooterComponent>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataProfile, dataProcess } = state;
  return {
    dataProfile: dataProfile.dataProfile,
    dataProcess: dataProcess.dataProcess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDataUserProcess: (data: object) => dispatch(setDataUserProcess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanRequest);

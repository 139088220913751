const HEADER = {
  "Content-Type": "application/json",
};

const API = {
  USER: "/api/v1/user",
  DOCUMENT: "/api/v1/document",
  CAR_INFO: "/api/v1/carInfo",
  PROCESS: "/api/v1/process",
  PROFILE: "/api/v1/profile",
  BANK: "/api/v1/bank",
  LOANS: "/api/v1/loan",
  PAYMENT: "/api/v1/payment",
};

const API_CONSTANTS = {
  USER: {
    USER_REGISTER: `${API.USER}/register`,
    USER_LOGIN: `${API.USER}/login`,
    USER_PROGRESS: `${API.USER}/progress/`,
    USER_GET_LOGIN: `${API.USER}/getLogin/`,
    RECOVERY_PASSWORD: `${API.USER}/recoveryPassword`,
    CHANGE_PASSWORD: `${API.USER}/changePassword`,
  },
  DOCUMENT: {
    UPLOAD_FILE: `${API.DOCUMENT}/upload`,
    FLOW_SCREEN_DOCUMENT: `${API.DOCUMENT}/getFlowScreenDocument`,
  },
  CAR_INFO: {
    SET_NEW_CAR_INFO: `${API.CAR_INFO}/create`,
  },
  PROCESS: {
    CREATE_PROCESS: `${API.PROCESS}/create`,
    GET_ALL_PROCESS: `${API.PROCESS}/getAll`,
    GET_PROCESS_BY_ID: `${API.PROCESS}/getById/`,
    GET_PERSONAL_INFO: `${API.PROCESS}/getPersonalInfo/`,
    GET_CAR_INFO: `${API.PROCESS}/getCarInfo/`,
    GET_BANK_INFO: `${API.PROCESS}/getBankInfo/`,
    UPDATE_BY_ID: `${API.PROCESS}/updateById/`,
    INFO_CREATE: `${API.PROCESS}/infoCreate`,
    UPDATE_BY_ID_PROCESSES: `${API.PROCESS}/updateByIdProcesses/`,
  },
  LOAN: {
    CREATE_LOAN: `${API.LOANS}/create`,
    GET_ALL_LOANS: `${API.LOANS}/getAll/`,
    OPENING_PRICE: `${API.LOANS}/openingPrice/`,
    GET_NEXT_PAYMENTS: `${API.LOANS}/getNextPayments/`,
  },
  PROFILE: {
    CREATE_PROFILE: `${API.PROFILE}/create`,
  },
  BANK: {
    CREATE_BANK: `${API.BANK}/create`,
  },
  PAYMENT: {
    CHECKOUT_PAYMENT: `${API.PAYMENT}/createCheckout`,
  },
  LOG_IN: {},
  SYSTEM_CONFIGURATION: {},
  SYSTEM_USER: {},
  CATALOGS: {},
  META_MAP: {},
  ADMIN: {},
};

export { API_CONSTANTS, HEADER };

import { API_CONSTANTS, HEADER } from "../constants/apiConstants";
import RequesterAxios from "../requester/requester";
import isNil from "lodash/isNil";

const callGlobalActionApi =
  (data, id, CONSTANT, method = "POST", token = true, headers = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const { dataProfile } = state;
    let response;
    if (token === true) {
      HEADER.Authorization = "Bearer " + dataProfile.dataProfile.token;
    }

    const config = {
      headers: {
        ...HEADER,
        ...headers,
      },
    };
    if (method === "POST") {
      response = await RequesterAxios.post(
        CONSTANT,
        { ...data, offset: getTimeZone() },
        config,
        token
      );
    } else if (method === "PUT") {
      response = await RequesterAxios.put(
        CONSTANT + id,
        { ...data, offset: getTimeZone() },
        config,
        token
      );
    } else if (method === "GET") {
      response = await RequesterAxios.get(CONSTANT + id, config);
    } else {
      response = await RequesterAxios.post(
        CONSTANT,
        { ...data, offset: getTimeZone() },
        config,
        token
      );
    }

    const responseResultStatus =
      isNil(response) === false && isNil(response.status) === false
        ? response.status
        : null;
    const responseResultMessage =
      isNil(response) === false &&
      isNil(response.data) === false &&
      isNil(response.data.detail) === false &&
      isNil(response.data.detail.errorMessage) === false
        ? response.data.detail.errorMessage
        : null;
    const responseResultData =
      isNil(response) === false && isNil(response.data) === false
        ? response.data
        : null;
    if (isNil(responseResultStatus) === false && responseResultStatus === 200) {
      return responseResultData;
    } else {
      throw isNil(responseResultMessage) === false
        ? responseResultMessage
        : null;
    }
  };

const callSetCustomerInDocument =
  (file, data, callback, method = "POST", token) =>
  async (dispatch, getState) => {
    const state = getState();
    const { dataProfile } = state;
    if (token === true) {
      HEADER.Authorization = "Bearer " + dataProfile.dataProfile.token;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify(data));

    const config = {
      headers: { ...HEADER, "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        callback(percentCompleted);
      },
    };
    let response = null;
    if (method === "POST") {
      response = await RequesterAxios.post(
        API_CONSTANTS.DOCUMENT.UPLOAD_FILE,
        formData,
        config
      );
    } else if (method === "PUT") {
      response = await RequesterAxios.put(
        API_CONSTANTS.DOCUMENT.UPLOAD_FILE,
        formData,
        config
      );
    } else {
      response = await RequesterAxios.post(
        API_CONSTANTS.DOCUMENT.UPLOAD_FILE,
        formData,
        config
      );
    }
    const responseResultStatus =
      isNil(response) === false && isNil(response.status) === false
        ? response.status
        : null;
    const responseResultMessage =
      isNil(response) === false &&
      isNil(response.data) === false &&
      isNil(response.data.response) === false &&
      isNil(response.data.response.message) === false
        ? response.data.response.message
        : null;
    const responseResultData =
      isNil(response) === false && isNil(response.data) === false
        ? response.data
        : null;
    if (isNil(responseResultStatus) === false && responseResultStatus === 200) {
      return responseResultData;
    } else {
      throw isNil(responseResultMessage) === false
        ? responseResultMessage
        : null;
    }
  };

const getTimeZone = () => {
  const offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
};

export { callSetCustomerInDocument, callGlobalActionApi };

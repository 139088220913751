import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import BackDark from "../../assets/img/swip-back-dark.png";
import BackDarkBtn from "../../assets/img/swip-back-dark-btm.png";
import HeaderComponent from "../../components/header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { setDataUserProfile } from "../../utils/dispatchs/userProfileDispatch";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import { useOnChangeInput } from "../../hooks";
import { setDataUserRegister } from "../../utils/dispatchs/userRegisterDispatch";
import Loader from "../../components/loader";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  overflow-y: scroll;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  margin-top: 2em;
  .terms-conditions {
    font-size: 0.7em;
    color: var(-primary-color);
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .buttons-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .option-init {
    text-align: center;
    margin-top: 1em;
    font-size: 0.8em;
    color: var(--secondary-color);
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  width: 70%;
  font-size: 1em;
`;

const LoginUser = (props) => {
  const initialState = {
    email: "",
    password: "",
  };
  const { setDataUserProfile, callGlobalActionApi, setDataUserRegister } =
    props;
  const navigate = useNavigate();
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [dataForm, handlerOnChange] = useOnChangeInput(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const handlerLogInUser = async () => {
    try {
      setIsLoading(true);
      const response = await callGlobalActionApi(
        dataForm,
        null,
        API_CONSTANTS.USER.USER_LOGIN,
        "POST",
        false
      );
      window.Intercom("boot", {
        app_id: "otekw0v8",
        email: response.email,
        name: response.profileInformation.name,
        user_id: response.idSystemUser,
      });
      if (
        isEmpty(response.profileInformation) === false &&
        response.screenNumber === 8
      ) {
        await setDataUserProfile(response);
      } else {
        await setDataUserRegister({
          idSystemUser: response.idSystemUser,
          idAddresses: response.idAddresses,
          idProcesses: response.idProcesses,
          idPersonalDocuments: response.idPersonalDocuments,
          idProfile: response.idProfile,
          idBankAccounts: response.idBankAccounts,
          idUserType: response.idUserType,
          screenNumber: response.screenNumber,
          idLoans: response.idLoans,
          username: response.username,
        });
      }
      navigate(response.path);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      window.alert(error);
    }
  };

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="light" isVisibleMenu={false} />
      <Main>
        <div className="backround-img-top">
          <img src={BackDark} alt="" />
        </div>
        <div className="backround-img-btm">
          <img src={BackDarkBtn} alt="" />
        </div>
        <div className="main-info">
          <h3>Inicio de Sesión</h3>
        </div>
        <div>
          <Box component="form" noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5em",
                marginTop: "2em",
              }}
            >
              <TextField
                required
                id="username"
                label="Ingresa tu correo electronico"
                defaultValue=""
                name="email"
                onChange={handlerOnChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  fontSize: "0.8em",
                  textAlign: "end",
                  position: "relative",
                  zIndex: "2",
                }}
                className="option-init"
              >
                <NavLink to="/recuperar-contrasena">
                  ¿No recuerdas la contraseña?
                </NavLink>
              </div>
              <TextField
                required
                id="password"
                label="Ingresa una contraseña"
                name="password"
                type={isVisiblePassword ? "text" : "password"}
                onChange={handlerOnChange}
                defaultValue=""
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setIsVisiblePassword(!isVisiblePassword);
                        }}
                        edge="end"
                      >
                        {isVisiblePassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Box>
          <ButtonsGroup>
            <div className="buttons-actions">
              <ButtonNext onClick={handlerLogInUser}>Iniciar Sesión</ButtonNext>
            </div>
            <div className="option-init">
              <span>
                ¿No tienes una cuenta?{" "}
                <NavLink to="/registro"> Registrate</NavLink>
              </span>
            </div>
          </ButtonsGroup>
        </div>
      </Main>
    </Container>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserProfile: (data) => dispatch(setDataUserProfile(data)),
  setDataUserRegister: (data) => dispatch(setDataUserRegister(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginUser);

import Roundy from "roundy";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";
import moment from "moment";
import { Container } from "../../assets/styles/styledGeneral";
import BackDark from "../../assets/img/swip-back-dark.png";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import NavCenterActionComponent from "../../components/navCenterAction";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { setDataUserProcess } from "../../utils/dispatchs/userProcessDispatch";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import Loader from "../../components/loader";

const SectionGauge = styled.div`
  position: relative;
  .range-total {
    position: absolute;
    font-size: 0.8em;
    right: -15px;
    bottom: 65px;
  }

  .sliderHandle::after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    right: 0px;
  }
`;

const Main = styled.main`
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--size-header) - var(--size-footer));
  display: grid;
  grid-template-rows: 240px 1fr;

  .title-page {
    margin: 2em 0px;
    h2 {
      font-weight: bold;
    }
  }
`;

const ContainerGauge = styled.div`
  display: flex;
  justify-content: center;
`;

const TopMain = styled.div`
  position: relative;
  .backround-img {
    position: relative;
    img {
      position: absolute;
      width: 100%;
    }
  }
`;

const BottomMain = styled.div`
  background: var(--primary-color);
  border-radius: 2em 2em 0px 0px;
  color: var(--light-color);
  padding: 1em;

  .title-verify {
    margin-top: 1em;
    padding: 1em 0px;
  }

  .content-info-to-confirm {
    margin-top: 1em;
    .amount-info {
      padding: 1em 0px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--light-color);
      margin-bottom: 1em;
      span:last-child {
        color: var(--secondary-color);
      }
    }
    .amount-info:last-child {
      border: none;
      span:first-child {
        font-size: 0.9em;
      }
    }
  }

  .info-date {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    align-items: center;
  }

  .account-bank {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    .bank-info {
      border-radius: 1em;
      padding: 1em 2em;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 0.5em;
      color: var(--primary-color);
      background: var(--info-light-background);
    }
  }

  .info-conditions {
    margin-top: 3em;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 0.8em;
    font-weight: 200;
  }
`;

const ChildGauge = styled.div`
  position: absolute;
  top: 30px;
  left: 37px;
  background: transparent;
  width: 190px;
  height: 111px;
  display: flex;
  row-gap: 1em;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--secondary-color);

  .container-amount {
    display: flex;
    justify-content: center;
  }

  .description-amount {
    font-weight: 200;
    p {
      font-size: 1.3em;
      margin: 0px;
    }
  }
`;

const StyleInput = styled.div`
  display: flex;
  align-items: baseline;
  h2 {
    margin: 0px;
    font-size: 2em;
  }
  span {
    font-size: 0.7em;
    font-weight: 100;
  }
`;

const BlockAmount = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  backdrop-filter: blur(2px);
`;

const LoanConfirm = (props) => {
  const { dataProfile, dataProcess, callGlobalActionApi, setDataUserProcess } =
    props;
  const [dataBankInfo, setDataBankInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const requestLoanAt = moment().format("YYYY-MM-DD HH:mm:ss");
  const nextLoanPaymentAt = moment(requestLoanAt)
    .add(1, "M")
    .format("YYYY-MM-DD HH:mm:ss");

  const parseFormatCurrency = (money, fraction, maxFraction) => {
    let resultNumber = "";
    if (isNil(money) === false) {
      const formatMoneyJson = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: fraction,
        maximumFractionDigits: maxFraction || 20,
      };
      const locale = "en-US";
      const moneyFormat = new Intl.NumberFormat(locale, formatMoneyJson);
      resultNumber = moneyFormat.format(money);
    }
    return resultNumber;
  };

  const handleDateFormat = (date) => {
    const day = moment(date).format("DD");
    const month = moment(date).format("MMMM");
    const year = moment(date).format("YY");

    return `${day} de ${month} ${year}`;
  };

  const parseValueStyle = (valueAmount) => {
    const valueOnchange = parseFormatCurrency(valueAmount, 0, 2);
    return valueOnchange.replace("$", "");
  };

  const handleGetBankInfo = async (id) => {
    try {
      const response = await callGlobalActionApi(
        {},
        id,
        API_CONSTANTS.PROCESS.GET_BANK_INFO,
        "GET",
        false
      );
      setDataBankInfo(response);
    } catch (error) {
      window.alert("Error al obtener los procesos");
    }
  };

  const handleCreateLoan = async (data) => {
    try {
      setIsLoading(true);
      await callGlobalActionApi(
        data,
        null,
        API_CONSTANTS.LOAN.CREATE_LOAN,
        "POST",
        false
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      window.alert("Error al crear un prestamo");
    }
  };

  const handleFormatBankInfo = (data) => {
    let infoTransfer = "";
    if (isEmpty(data) === false) {
      const { bank, clabe } = data;
      infoTransfer = `${bank} CLABE ${clabe}`;
    }

    return infoTransfer;
  };

  useEffect(() => {
    handleGetBankInfo(dataProfile.idSystemUser);
  }, []);

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="light" />
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          className="backround-img"
          style={{
            position: "absolute",
          }}
        >
          <img src={BackDark} alt="" />
        </div>
        <Main>
          <TopMain>
            <BlockAmount />
            <div className="title-page">
              <h2></h2>
            </div>
            <ContainerGauge>
              <SectionGauge>
                <ChildGauge>
                  <div className="description-amount">
                    <p>Monto</p>
                  </div>
                  <div className="container-amount" onClick={() => {}}>
                    <StyleInput>
                      <h2>$</h2>
                      <h2>
                        {parseValueStyle(dataProcess?.metaDataLoan?.amountLoan)}
                      </h2>
                      {/* <CurrencyInputComponent
                      value={value}
                      onChange={(value) => {
                        setValue(value);
                      }}
                    /> */}
                      <span>MXN</span>
                    </StyleInput>
                  </div>
                </ChildGauge>
                <Roundy
                  strokeWidth={15}
                  thumbSize={3}
                  value={dataProcess?.metaDataLoan?.amountLoan}
                  arcSize={220}
                  min={1}
                  max={dataProcess?.amountAvailable}
                  stepSize={10000}
                  radius={130}
                  color="var(--secondary-color)"
                  bgColor="var(--primary-color)"
                  onChange={() => {}}
                  onAfterChange={() => {}}
                  overrideStyle={{}}
                  rotationOffset={-20}
                  sliced={false}
                />
              </SectionGauge>
            </ContainerGauge>
          </TopMain>
          <BottomMain>
            <div className="title-verify">
              <h2>Por favor verifica que todo este bien:</h2>
            </div>
            <div className="content-info-to-confirm">
              <div className="amount-info">
                <span>Monto que recibes</span>
                <span>
                  {parseFormatCurrency(
                    dataProcess?.metaDataLoan?.amountLoan,
                    0,
                    2
                  )}
                </span>
              </div>
              <div className="amount-info">
                <span>Monto mensual que pagas</span>
                <span>
                  {parseFormatCurrency(
                    dataProcess?.metaDataLoan?.amountMonthly,
                    0,
                    2
                  )}
                </span>
              </div>
              <div className="amount-info">
                <span>Total para liquidar el mes siguiente</span>
                <span>
                  {parseFormatCurrency(
                    dataProcess?.metaDataLoan?.amountPayOff,
                    0,
                    2
                  )}
                </span>
              </div>
            </div>
            <div className="info-date">
              <span>Tu siguiente pago se debe realizar antes del:</span>
              <strong>{handleDateFormat(nextLoanPaymentAt)}</strong>
            </div>
            <div className="account-bank">
              <div className="bank-info">
                <span>El prestamo se deposita en tu cuenta</span>
                <strong>{handleFormatBankInfo(dataBankInfo)}</strong>
              </div>
            </div>
            <div className="info-conditions">
              <span>
                Al confirmar, aceptas los Términos y Condiciones y el acuerdo de
                prestamos con SWIP S.A.P.I de CV
              </span>
            </div>
          </BottomMain>
        </Main>
      </div>
      <FooterComponent type="dark">
        <NavCenterActionComponent
          label="Confirmar"
          type="light"
          onClick={async (navTo) => {
            const data = {
              idBankAccount: dataBankInfo?.idBankAccount,
              idSystemUser: dataProcess?.idSystemUser,
              idProcesses: dataProcess?.idProcesses,
              idProcess: dataProcess?.idProcess,
              idLoans: dataProcess?.idLoans,
              concept: "Préstamo",
              amountMonthly: dataProcess?.metaDataLoan?.amountMonthly,
              amountLoan: dataProcess?.metaDataLoan?.amountLoan,
              amountIva: dataProcess?.metaDataLoan?.amountIva,
              amountInterest: dataProcess?.metaDataLoan?.amountInterest,
              amountPayOff: dataProcess?.metaDataLoan?.amountPayOff,
            };

            await handleCreateLoan(data);
            navTo("/confirmado?type=ready");
            setDataUserProcess({
              ...dataProcess,
              metaDataLoan: {
                amountLoan: 0,
                amountMonthly: 0,
                amountIva: 0,
                amountInterest: 0,
                amountPayOff: 0,
              },
            });
          }}
        />
      </FooterComponent>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { dataProfile, dataProcess } = state;
  return {
    dataProfile: dataProfile.dataProfile,
    dataProcess: dataProcess.dataProcess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
  setDataUserProcess: (data: object) => dispatch(setDataUserProcess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanConfirm);

import styled from "styled-components";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import BackDark from "../../assets/img/swip-back-dark.png";
import BackDarkBtn from "../../assets/img/swip-back-dark-btm.png";
import HeaderComponent from "../../components/header";
import { useState } from "react";
import { callGlobalActionApi } from "../../utils/actions/actions";
import { API_CONSTANTS } from "../../utils/constants/apiConstants";
import { useOnChangeInput } from "../../hooks";
import Loader from "../../components/loader";

const Container = styled.div`
  display: grid;
  grid-template-rows: var(--size-header) 1fr;
  height: 100vh;
  color: var(--primary-color);
  background: var(--light-color);
`;

const Main = styled.div`
  padding: 1em 2em;
  position: relative;
  overflow-y: scroll;
  .backround-img-top {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 100%;
    }
  }

  .backround-img-btm {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }

  .main-info {
    position: relative;
    p {
      font-size: 0.8em;
    }
  }
`;

const ButtonsGroup = styled.div`
  position: relative;
  margin-top: 2em;
  .terms-conditions {
    font-size: 0.7em;
    color: var(-primary-color);
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .buttons-actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .option-init {
    text-align: center;
    margin-top: 1em;
    font-size: 0.8em;
    color: var(--secondary-color);
  }
`;

const ButtonNext = styled.button`
  font-weight: bold;
  border: none;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1em 2em;
  border-radius: 1.2em;
  width: 70%;
  font-size: 1em;
`;

const RecoveryPassword = (props) => {
  const initialState = {
    email: "",
  };
  const { callGlobalActionApi } = props;
  const navigate = useNavigate();
  const [dataForm, handlerOnChange] = useOnChangeInput(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const handlerRecoveryUser = async () => {
    try {
      setIsLoading(true);
      await callGlobalActionApi(
        dataForm,
        null,
        API_CONSTANTS.USER.RECOVERY_PASSWORD,
        "POST",
        false
      );
      window.alert("Se ha enviado un correo con las instrucciones, si no lo ves revisa tu bandeja de spam");
      navigate("/login");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      window.alert(error);
    }
  };

  return (
    <Container>
      <Loader isVisible={isLoading} />
      <HeaderComponent type="light" isVisibleMenu={false} />
      <Main>
        <div className="backround-img-top">
          <img src={BackDark} alt="" />
        </div>
        <div className="backround-img-btm">
          <img src={BackDarkBtn} alt="" />
        </div>
        <div className="main-info">
          <h3>Recuperación de contraseña</h3>
        </div>
        <div>
          <Box component="form" noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5em",
                marginTop: "2em",
              }}
            >
              <TextField
                required
                id="username"
                label="Ingresa tu correo electronico"
                defaultValue=""
                name="email"
                onChange={handlerOnChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Box>
          <div
            style={{
              marginTop: "1em",
            }}
          >
            <span
              style={{
                fontSize: "0.8em",
              }}
            >
              Te enviaremos un correo con un link para que puedas recuperar tu
              contraseña
            </span>
          </div>
          <ButtonsGroup>
            <div className="buttons-actions">
              <ButtonNext onClick={handlerRecoveryUser}>Enviar</ButtonNext>
            </div>
            {/* <div className="option-init">
              <span>
                ¿No tienes una cuenta?{" "}
                <NavLink to="/registro"> Registrate</NavLink>
              </span>
            </div> */}
          </ButtonsGroup>
        </div>
      </Main>
    </Container>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  callGlobalActionApi: (data, id, constant, method, token) =>
    dispatch(callGlobalActionApi(data, id, constant, method, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);
